import { SearchOutlined } from '@ant-design/icons';
import { Col, DatePicker, Input, Row, Select, Space } from 'antd';
import { CompanyAdminStatus } from 'constants/Data';
import styles from './index.module.scss';

interface Props {
	searchValue: string;
	filterValue: string;
	dateValue: {
		startDate: string;
		endDate: string;
	};
	performSearchHandler: (value: string) => void;
	performFilterHandler: (value: string) => void;
	performDateHandler: any;
}

const SearchAndFilter = (props: Props) => {
	const {
		searchValue,
		filterValue,
		// dateValue,
		performSearchHandler,
		performFilterHandler,
		performDateHandler,
	} = props;

	const { RangePicker } = DatePicker;

	return (
		<div className={styles['search-filter']}>
			<div className={styles['search-filter__wrapper']}>
				<Row justify="space-between">
					<Col>
						<Space>
							<Input
								className={styles['search-filter__search']}
								placeholder="Search here..."
								suffix={<SearchOutlined />}
								size="large"
								value={searchValue}
								onChange={(e) => performSearchHandler(e.target.value)}
							/>
							<Select
								className={styles['search-filter__filter']}
								style={{ width: 200 }}
								size="large"
								placeholder="Status"
								showSearch
								filterOption={(input, option: any) =>
									(option?.children as string)
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
								value={filterValue}
								onChange={(value) => performFilterHandler(value)}
							>
								<Select.Option value="All">All</Select.Option>
								<Select.Option value={CompanyAdminStatus.ACTIVE}>
									Active
								</Select.Option>
								<Select.Option value={CompanyAdminStatus.INACTIVE}>
									Inactive
								</Select.Option>
							</Select>
							<RangePicker
								className="range-picker"
								size="large"
								style={{ width: 260 }}
								format="MM/DD/YYYY"
								onChange={performDateHandler}
								// disabledDate={(date) => isDateDisabled(date)}
								// onFocus={() => {
								// 	focusChangeHandler(data);
								// }}
								// status={data.isError}
								// value={selectedRange}
								// onChange={(rangeData: any) => {
								// 	editPayPeriodHandler(
								// 		data,
								// 		rangeData?.[0]?.['$d'] || null,
								// 		rangeData?.[1]?.['$d'] || null
								// 	);
								// }}
							/>
						</Space>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default SearchAndFilter;
