import { createSlice } from '@reduxjs/toolkit';
import {
	getCustomRulesAction,
	getDeleteCustomRulesAction,
	getEditCustomRulesAction,
	getSplitCustomRulesAction,
} from 'redux/action/customRuleAction';

const initialState = {
	isLoading: false,
	data: [],
	error: null,
	splitIsLoading: false,
	splitData: [],
	splitError: null,
	editIsLoading: false,
	editData: [],
	editError: null,
	deleteIsLoading: false,
	deleteData: [],
	deleteError: null,
};

const customRuleSlice = createSlice({
	initialState,
	name: 'customRule',
	reducers: {
		updateCustomRules: (state, action) => {
			switch (action.payload.key) {
				case 'split':
					state.splitData = action.payload.data;
					break;
				case 'edit':
					state.editData = action.payload.data;
					break;
				case 'delete':
					state.deleteData = action.payload.data;
					break;
				default:
					state.data = action.payload.data;
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getCustomRulesAction.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getCustomRulesAction.fulfilled, (state, action) => {
			state.data = action.payload.content;
			state.isLoading = false;
			state.error = null;
		});
		builder.addCase(getCustomRulesAction.rejected, (state: any, action) => {
			state.error = action.payload;
			state.isLoading = false;
			state.data = [];
		});

		builder.addCase(getSplitCustomRulesAction.pending, (state) => {
			state.splitIsLoading = true;
		});
		builder.addCase(getSplitCustomRulesAction.fulfilled, (state, action) => {
			state.splitData = action.payload.content;
			state.splitIsLoading = false;
			state.splitError = null;
		});
		builder.addCase(
			getSplitCustomRulesAction.rejected,
			(state: any, action) => {
				state.splitError = action.payload;
				state.splitIsLoading = false;
				state.splitData = [];
			}
		);

		builder.addCase(getEditCustomRulesAction.pending, (state) => {
			state.editIsLoading = true;
		});
		builder.addCase(getEditCustomRulesAction.fulfilled, (state, action) => {
			state.editData = action.payload.content;
			state.editIsLoading = false;
			state.editError = null;
		});
		builder.addCase(getEditCustomRulesAction.rejected, (state: any, action) => {
			state.editError = action.payload;
			state.editIsLoading = false;
			state.editData = [];
		});

		builder.addCase(getDeleteCustomRulesAction.pending, (state) => {
			state.deleteIsLoading = true;
		});
		builder.addCase(getDeleteCustomRulesAction.fulfilled, (state, action) => {
			state.deleteData = action.payload.content;
			state.deleteIsLoading = false;
			state.deleteError = null;
		});
		builder.addCase(
			getDeleteCustomRulesAction.rejected,
			(state: any, action) => {
				state.deleteError = action.payload;
				state.deleteIsLoading = false;
				state.deleteData = [];
			}
		);
	},
});

export default customRuleSlice;

export const { updateCustomRules } = customRuleSlice.actions;
