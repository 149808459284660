import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { QuickbooksEmployee } from 'utils/svgs';
import styles from './index.module.scss';
import './index.scss';
import { NumericFormat } from 'react-number-format';

interface Props {
	tableChangeHandler: (value1: any, value2: any, data: any) => void;
}

const CustomerSummaryTable = (props: Props) => {
	const { Column } = Table;

	const { tableChangeHandler } = props;

	const { customerSummaryData, isCustomerSummaryLoading } = useSelector(
		(state: any) => state.reports
	);
	const { configurations: configurationsData } = useSelector(
		(state: any) => state.companies
	);

	const qboMode = process.env.REACT_APP_QBO_MODE ? process.env.REACT_APP_QBO_MODE : 'sandbox'

	return (
		<div className="customer-summary-report">
			<Table
				dataSource={customerSummaryData}
				scroll={{ y: '43vh' }}
				pagination={false}
				className="table-global customer-summary-report-table"
				rowKey={(record: any) => {
					return record.name;
				}}
				style={{
					overflowY: 'auto',
				}}
				onChange={tableChangeHandler}
				loading={isCustomerSummaryLoading}
			>
				<Column
					title="Customer/Company Name"
					dataIndex="name"
					key="name"
					className="bg-white"
					render={(value, data: any) => {
						return (
							<a
								className={styles['dynamic-table__customer']}
								href={`https://app${qboMode != 'prod' ? '.sandbox' : ''}.qbo.intuit.com/app/customerdetail?nameId=${data.id}`}
								target="blank"
							>
								<span className={styles['dynamic-table__customer-name']}>
									{value}
								</span>
								<QuickbooksEmployee />
							</a>
						);
					}}
					align="left"
					width={50}
				/>
				<Column
					title="Payroll Cost"
					dataIndex="expense"
					key="expense"
					className="bg-white custom-cell-css"
					render={(value) => {
						return (
							<NumericFormat
								value={value}
								thousandSeparator=","
								decimalScale={configurationsData?.decimalToFixedAmount || 2}
								fixedDecimalScale={true}
								displayType="text"
								prefix="$"
							/>
						);
					}}
					align="left"
					width={50}
				/>
			</Table>
		</div>
	);
};

export default CustomerSummaryTable;
