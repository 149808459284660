import { useState, useEffect } from 'react';
import { postApi } from 'redux/apis';
import styles from './index.module.scss';

const QuickbooksSSOCallback = () => {
	const [isLoading, setIsLoading] = useState(false);

	const fetchData = async () => {
		setIsLoading(true);

		const response = await postApi('/quickbooks/sso-callback', {
			url: window.location.href,
			isLogin: localStorage.getItem('isLogin') === 'true' ? true : false,
		});

		console.log('My response: ', response);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className={styles['main-container']}>
			{isLoading && <img src="assets/gifs/loading-black.gif" />}
		</div>
	);
};

export default QuickbooksSSOCallback;
