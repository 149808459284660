import { Select } from 'antd';
import styles from './index.module.scss';
import './index.scss';
import { JournalSettingsProps } from './types';

const JournalSettings = (props: JournalSettingsProps) => {
	const { isClassRequiredForJournal, isCustomerRequiredForJournal, handleIsClassRequiredForJournal, handleIsCustomerRequiredForJournal } = props;
	return (
		<div className={styles['account-settings']}>
			<div className={styles['account-settings__wrapper']}>
				<div className={styles['account-settings__top']}>
					<p className={styles['account-settings__top--title']}>
						Time Settings
					</p>
				</div>
				<div className={styles['account-settings__center']}>
					<div className={styles['account-settings__center--expense']}>
						<label
							className={styles['account-settings__center--expense--label']}
						>
							Class Field Required
						</label>

						<Select
							value={isClassRequiredForJournal}
							style={{ width: 150 }}
							onChange={(value) => handleIsClassRequiredForJournal(value)}
							options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
						/>
					</div>
					<div className={styles['account-settings__center--expense']}>
						<label
							className={styles['account-settings__center--expense--label']}
						>
							Customer Field Required
						</label>

						<Select
							value={isCustomerRequiredForJournal}
							style={{ width: 150 }}
							onChange={(value) => handleIsCustomerRequiredForJournal(value)}
							options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default JournalSettings;
