import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi } from 'redux/apis';

export const getTimeSummaryReportAction = createAsyncThunk(
	'getTimeSummaryReport/get',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getApi('/reports/time-activity-summary', {
				...params,
				companyId: localStorage.getItem('companyId'),
			});
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const getCustomerSummaryReportAction = createAsyncThunk(
	'getCustomerSummaryReport/get',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getApi('/reports/customer-expense-summary', {
				...params,
				companyId: localStorage.getItem('companyId'),
			});
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const getPayrollSummaryReportAction = createAsyncThunk(
	'getPayrollSummaryReport/get',
	async (params: any, { rejectWithValue }) => {
		try {
			const response = await getApi('/reports/payroll-expense-summary', {
				...params,
				companyId: localStorage.getItem('companyId'),
			});
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error?.response?.data);
		}
	}
);
