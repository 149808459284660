import { Col, Row } from 'antd';
import { monthNames } from 'constants/Data';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generateYearRange, getMonthAfter12Months } from 'utils/utils';
import CostAllocationSummary from './CostAllocationSummary';
import CustomerPayrollExpense from './CustomerPayrollExpense';
import EmployeeHours from './EmployeeHoursByPayPeriod';
import SalaryExpenseByMonth from './SalaryExpenseByMonth';
import styles from './index.module.scss';
import './index.scss';

import { Select } from 'antd';

export default function DashboardComponent() {
	const [yearRange, setYearRange] = useState<any>([]);
	const [selectedYear, setSelectedYear] = useState(yearRange[0]);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state?.companies
	);

	const handleYearFilter = (value: any) => {
		setSelectedYear(value);
	};

	useEffect(() => {
		const startMonth = selectedCompanyDetails?.company?.fiscalYear;
		const endMonth = getMonthAfter12Months(
			monthNames.indexOf(selectedCompanyDetails?.company?.fiscalYear) + 1
		);
		const currentYear = new Date().getFullYear();
		const years = generateYearRange(startMonth, endMonth, currentYear, 5);

		const data = years.reverse().map((year) => {
			return {
				key: Math.random(),
				label: year,
				value: year,
			};
		});
		setSelectedYear(data[0] && data[0]?.value);
		setYearRange(data);
	}, [selectedCompanyDetails]);

	return (
		<div className="dashboard">
			{/* <DatePicker
				size="large"
				picker="year"
				onChange={handleYear}
				value={year}
				clearIcon={false}
			/> */}
			<div className={styles['date-filter']}>
				<Select
					style={{ width: 280 }}
					size="large"
					value={selectedYear}
					onChange={handleYearFilter}
					options={yearRange}
				/>
			</div>
			<Row gutter={24} className={styles['graph-main']}>
				<Col
					xs={24}
					sm={12}
					md={12}
					lg={12}
					className={styles['graph-main__col']}
				>
					<div className={styles['graph-main__col--customer-expense']}>
						<CustomerPayrollExpense year={selectedYear} />
					</div>
				</Col>

				<Col
					xs={24}
					sm={12}
					md={12}
					lg={12}
					className={styles['graph-main__col']}
				>
					<div className={styles['graph-main__col--employee-hours']}>
						<EmployeeHours year={selectedYear} />
					</div>
				</Col>
				<Col
					xs={24}
					sm={12}
					md={12}
					lg={12}
					className={styles['graph-main__col']}
				>
					<div className={styles['graph-main__col--employee-expense']}>
						<SalaryExpenseByMonth year={selectedYear} />
					</div>
				</Col>

				<Col
					xs={24}
					sm={12}
					md={12}
					lg={12}
					className={styles['graph-main__col']}
				>
					<div className={styles['graph-main__col--cost-allocation']}>
						<CostAllocationSummary />
					</div>
				</Col>
			</Row>
		</div>
	);
}
