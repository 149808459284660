import { Col, Modal, Row, Button } from 'antd';
import './index.scss';

interface Props {
	isOpen: boolean;
	handleCancel: () => void;
	handleSubmit: () => void;
}

const ValidTimeSheetModal = (props: Props) => {
	const { isOpen, handleCancel, handleSubmit } = props;

	return (
		<Modal
			open={isOpen}
			onCancel={handleCancel}
			closable={false}
			footer={null}
			className="time-sheet-validate-modal profile__popup"
		>
			<p>
				Some time entries lack customer and/or class selections. If you proceed,
				these entries will be excluded from the cost allocation. Click cancel to
				return and make necessary edits.
			</p>
			<Row style={{ marginTop: '15px' }}>
				<Col xs={24} md={8} lg={8} sm={8}>
					<Button className={`save`} onClick={handleSubmit}>
						Continue
					</Button>
				</Col>
				<Col xs={24} md={16} lg={16} sm={16}>
					<Button className={`cancel`} onClick={handleCancel}>
						Cancel
					</Button>
				</Col>
			</Row>
		</Modal>
	);
};

export default ValidTimeSheetModal;
