import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { CloseSvg } from 'utils/svgs';
import style from './index.module.scss';
import './index.scss';

interface Props {
	isModalOpen: boolean;
	handleOk: (value: string) => void;
	handleCancel: () => void;
	isPasswordLoading: boolean;
}

const ChangePasswordModal = (props: Props) => {
	const { isModalOpen, handleCancel, handleOk, isPasswordLoading } = props;

	const [form] = Form.useForm();
	return (
		<Modal
			open={isModalOpen}
			mask={true}
			footer={false}
			closable={false}
			className="get__support-modal"
		>
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				autoComplete="off"
				form={form}
				onFinish={handleOk}
			>
				<Row className={style['get__support-modal-header']}>
					<div className={style['get__support-modal-title']}>
						<b> Change Password</b>
					</div>
					<div
						className={style['get__support-modal-close']}
						onClick={handleCancel}
					>
						<CloseSvg />
					</div>
				</Row>
				<hr />
				<div className={style['get__support-modal-body']}>
					<Form.Item
						className="formItem"
						wrapperCol={{ span: 24 }}
						name="password"
						required={true}
						rules={[
							{
								required: true,
								message: 'Please enter password',
								validateTrigger: 'onChange',
							},
							{
								pattern:
									/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,16}$/,
								message:
									'Password should have minimum 8 and maximum 16 characters with at least 1 uppercase, 1 lowercase, 1 number and 1 special character',
								validateTrigger: 'onChange',
							},
						]}
					>
						<Input.Password
							placeholder="password"
							size="large"
							// className={styles['input-icon__form--input']}
						/>
					</Form.Item>
				</div>
				<hr />
				<Row
					justify="start"
					className={style['get__support-modal-footer']}
					gutter={16}
				>
					<Col xs={12} md={7} lg={7} sm={8}>
						<Button
							htmlType="submit"
							className={`${style[`get__support-modal-footer-save`]} ${
								isPasswordLoading && 'pointer-event-none'
							}`}
						>
							{isPasswordLoading ? (
								<img src="/assets/gifs/loading-black.gif" height={38} />
							) : (
								'Reset'
							)}
						</Button>
					</Col>
					<Col xs={12} md={7} lg={7} sm={8}>
						<Button
							block={true}
							onClick={handleCancel}
							className={style['get__support-modal-footer-cancel']}
						>
							Cancel
						</Button>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default ChangePasswordModal;
