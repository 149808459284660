import { AuthLayout } from 'components/Global/AuthLayout';
import GlobalLayout from 'layouts/Global';
import {
	ForgotPassword,
	Home,
	Login,
	ResetPassword,
	TimeActivity,
} from 'pages';
import CostAllocationPage from 'pages/CostAllocation';
import EmployeeCosts from 'pages/EmployeeCosts';
import QuickbooksCallback from 'pages/QuickbooksCallback';
import Unauthorized from 'pages/Unauthorized';
import { createBrowserRouter } from 'react-router-dom';
import JournalEntries from 'pages/JournalEntries';
import Reports from 'pages/Reports';
import QuickbooksSSOCallback from 'pages/QuickbooksSSOCallback';
import SuperAdmin from 'pages/SuperAdmin';
import QuickbooksLaunchCallback from 'pages/QuickbooksLaunchCallback';
import QuickbooksDisconnectCallback from 'pages/QuickbooksDisconnectCallback';
import QuickbooksAppInstallCallback from 'pages/QuickbooksAppInstallCallback';

const router = createBrowserRouter([
	{
		element: <AuthLayout />,
		children: [
			{
				path: '/',
				element: <GlobalLayout />,
				children: [
					{
						index: true,
						element: <Home />,
					},
					{
						path: 'time-activity',
						element: <TimeActivity />,
					},
					{
						path: 'employee-costs',
						element: <EmployeeCosts />,
					},
					{
						path: 'cost-allocations',
						element: <CostAllocationPage />,
					},
					{
						path: 'journal-entries',
						element: <JournalEntries />,
					},
					{
						path: 'reports',
						element: <Reports />,
					},
					{
						path: 'admin',
						element: <SuperAdmin />,
					},
				],
			},
			{
				path: '/login',
				element: <Login />,
			},
			{
				path: '/reset-password',
				element: <ResetPassword />,
			},
			{
				path: '/forgot-password',
				element: <ForgotPassword />,
			},
			{
				path: '/callback',
				element: <QuickbooksCallback />,
			},
			{
				path: '/sso-callback',
				element: <QuickbooksSSOCallback />,
			},
			{
				path: '/launch-callback',
				element: <QuickbooksLaunchCallback />,
			},
			{
				path: '/disconnect-callback',
				element: <QuickbooksDisconnectCallback />,
			},
			{
				path: '/install-app-from-quickbooks',
				element: <QuickbooksAppInstallCallback />,
			},
			{
				path: '/unauthorized',
				element: (
					<Unauthorized
						status={401}
						title="Unauthorized"
					/>
				),
			},
			{
				path: '*',
				element: (
					<Unauthorized
						status={404}
						title="Page Not Found"
					/>
				),
			},
		],
	},
]);

export default router;
