import { SyncNow } from 'components/Global';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { refreshClass } from 'redux/action/classAction';
import { refreshOfAccountAction } from 'redux/action/chartOfAccountAction';
import { refreshAccountAction } from 'redux/action/customerAction';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { toastText } from 'utils/utils';
import PayPeriodFilter from 'components/Global/PayPeriodFilter';
import { useSearchParams } from 'react-router-dom';

// Component for main configurations
const ConfigurationHeader = ({ onChangePayPeriodId }: any) => {
	// Init
	const dispatch = useDispatch<AppDispatch>();
	// for manage the sync now button

	const [queryParams] = useSearchParams();

	const [lastSyncTime, setLastSyncTime] = useState(new Date());
	const [payPeriodId, setPayPeriodId] = useState<string | null>(
		localStorage.getItem('payPeriod') || null
	);

	const { optionData: payPeriods, isPayPeriodLength } = useSelector(
		(state: any) => state?.payPeriods
	);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const configureLastSync = useSelector(
		(state: any) =>
			state?.companies?.selectedCompanyDetails?.company?.classLastSyncDate
	);

	useEffect(() => {
		setLastSyncTime(configureLastSync);
	}, [configureLastSync]);

	const [isLoading, setIsLoading] = useState(false);

	const syncNowHandler = () => {
		setIsLoading(true);
		dispatch(refreshClass());
		dispatch(refreshOfAccountAction());
		dispatch(refreshAccountAction()).then(() => {
			setIsLoading(false);
			toastText('Configurations synced successfully', 'success');
		});
		setLastSyncTime(new Date());
	};

	const handleChangePayPeriodId = (_payPeriodId: string) => {
		setPayPeriodId(_payPeriodId);
		onChangePayPeriodId(_payPeriodId);
	};

	useEffect(() => {
		if (selectedCompanyDetails && payPeriods && payPeriods.length) {
			let _payPeriodId: string | null = null;
			if (payPeriods.length) {
				if (
					payPeriods[payPeriods.length - 1].companyId ===
					selectedCompanyDetails.companyId
				) {
					_payPeriodId = payPeriodId;
					if (_payPeriodId) {
						const payPeriodData = payPeriods.find(
							(e: any) => e.id === payPeriodId
						);
						if (
							!payPeriodData ||
							payPeriodData.companyId != selectedCompanyDetails.companyId
						) {
							_payPeriodId = payPeriods[payPeriods.length - 1]?.id || null;
							// setPayPeriodId(payPeriods[payPeriods.length - 1]?.id || null);
						}
					} else {
						// setPayPeriodId(payPeriods[payPeriods.length - 1]?.id || null);
						_payPeriodId = payPeriods[payPeriods.length - 1]?.id || null;
					}

					setPayPeriodId(_payPeriodId);
					onChangePayPeriodId(_payPeriodId);
				}
			}
		}
	}, [payPeriods]);

	// JSX
	return (
		<div className={styles['configuration']}>
			<div className={styles['configuration__wrapper']}>
				<div style={{ display: 'flex', gap: '3rem' }}>
					<h3 className={styles['configuration__title']}>
						Mapping and General Settings
					</h3>
					<div>
						<PayPeriodFilter
							payPeriodId={payPeriodId}
							onChangePayPeriodId={(value) => handleChangePayPeriodId(value)}
							createNewPayPeriod={true}
							defaultModalOpen={!isPayPeriodLength || (queryParams.get('createNewPayPeriod') && queryParams.get('createNewPayPeriod') === 'true') ? true : false}
						/>
					</div>
				</div>
				<div className={styles['configuration__sync']}>
					<SyncNow
						isLastSyncNeeded={false}
						syncDate={lastSyncTime}
						isLoading={isLoading}
						handleSync={syncNowHandler}
						tooltip=""
						key={1}
						title="Refresh"
					/>
				</div>
			</div>
		</div>
	);
};

export default ConfigurationHeader;
