import { createSlice } from '@reduxjs/toolkit';
import {
	classAction,
	classOptionsAction,
	refreshClass,
} from 'redux/action/classAction';
const initialState = {
	isLoading: false,
	data: [],
	error: null,
	optionData: [],
	isOptionLoading: false,
	optionError: null,
};

const classSlice = createSlice({
	initialState,
	name: 'class',
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(classAction.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(classAction.fulfilled, (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		});
		builder.addCase(classAction.rejected, (state, action: any) => {
			state.error = action.payload;
			state.isLoading = false;
		});
		builder.addCase(classOptionsAction.pending, (state) => {
			state.isOptionLoading = true;
		});
		builder.addCase(classOptionsAction.fulfilled, (state, action) => {
			state.optionData = action.payload;
			state.isOptionLoading = false;
		});
		builder.addCase(classOptionsAction.rejected, (state, action: any) => {
			state.optionError = action.error;
			state.isOptionLoading = false;
		});
		builder.addCase(refreshClass.fulfilled, (state, action) => {
			state.data = action.payload;
		});
	},
});

export default classSlice;
