import { Header, Sidebar } from 'components/Global';
import { FORMDATA } from 'constants/Data';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Outlet,
	useLocation,
	useNavigate,
	// useSearchParams,
} from 'react-router-dom';
import { checkPermission } from 'utils/utils';
import styles from './index.module.scss';
import { LayoutExpandInterFace } from 'pages/CostAllocation/types';
import { fetchClosingDateAction } from 'redux/action/closingDateAction';
import { AppDispatch } from 'redux/store';
import useIdleRedirect from 'utils/logoutTimer';
// Creating the global layout for along all the pages
const GlobalLayout = () => {
	// inits
	const { pageMenuItems } = FORMDATA;
	const { isLoading, data: userData } = useSelector(
		(state: any) => state.userProfile
	);
	const { pathname } = useLocation();
	const [finalMenuItems, setMenuItems] = useState<any>([]);

	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const dispatch = useDispatch<AppDispatch>();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	const { isExpanded } = useSelector(
		(state: LayoutExpandInterFace) => state?.layoutExpand
	);

	const navigate = useNavigate();

	// const [queryParams] = useSearchParams();

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const isDashboardPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Dashboard',
		permission: ['view'],
	});

	const isEmployeePermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Employee Cost',
		permission: ['view'],
	});

	const isTimeLogPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Logs',
		permission: ['view'],
	});

	const isTimeSheetPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Sheets',
		permission: ['view'],
	});

	const isCostAllocationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Cost Allocations',
			permission: ['view'],
		}
	);

	const isJournalEntriesPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Journals Entries',
			permission: ['view'],
		}
	);

	const isTimeSummaryPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Summary',
		permission: ['view'],
	});

	const isPayrollSummaryPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Payroll Summary',
			permission: ['view'],
		}
	);

	const isCustomerSummaryPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Customer Overview',
			permission: ['view'],
		}
	);

	// const isPayPeriodsPermission = checkPermission(selectedCompanyPermission, {
	// 	permissionName: 'Pay Period',
	// 	permission: ['view'],
	// });

	useEffect(() => {
		let menuItems = pageMenuItems;
		if (!isDashboardPermission) {
			menuItems = menuItems?.filter((item) => item?.key !== 'dashboard');
		}

		// if (!isEmployeePermission && !isPayPeriodsPermission) {
		// 	menuItems = menuItems?.filter((item) => item?.key !== 'employee-costs');
		// }

		if (!isTimeLogPermission && !isTimeSheetPermission) {
			menuItems = menuItems?.filter((item) => item?.key !== 'time-activity');
		}

		if (!isCostAllocationPermission && !isJournalEntriesPermission) {
			menuItems = menuItems?.filter((item) => item?.key !== 'cost-allocations');
		}

		if (!isJournalEntriesPermission) {
			menuItems = menuItems?.filter((item) => item?.key !== 'journal-entries');
		}

		if (
			!isTimeSummaryPermission &&
			!isPayrollSummaryPermission &&
			!isCustomerSummaryPermission
		) {
			menuItems = menuItems?.filter((item) => item?.key !== 'reports');
		}

		setMenuItems(menuItems);
	}, [
		isEmployeePermission,
		isTimeLogPermission,
		isDashboardPermission,
		isCostAllocationPermission,
		isTimeSheetPermission,
		isJournalEntriesPermission,
		pageMenuItems,
	]);

	const [selectedSidebar, setSelectedSidebar] = useState<string>('dashboard');

	const handleSidebar = (sidebarProps: any) => {
		if (sidebarProps?.key === 'employee-costs') {
			setSelectedSidebar('employee-costs');
			navigate('/employee-costs');
		} else if (sidebarProps?.key === 'time-activity') {
			setSelectedSidebar('time-activity');
			navigate('/time-activity');
		} else if (sidebarProps?.key === 'cost-allocations') {
			setSelectedSidebar('cost-allocations');
			navigate('/cost-allocations');
		} else if (sidebarProps?.key === 'journal-entries') {
			setSelectedSidebar('journal-entries');
			navigate('/journal-entries');
		} else if (sidebarProps?.key === 'reports') {
			setSelectedSidebar('reports');
			navigate('/reports');
		} else {
			setSelectedSidebar('dashboard');
			navigate(`/`);
		}
	};
	const initialFunctionCall = () => {
		if (pathname === '/employee-costs') {
			setSelectedSidebar('employee-costs');
		} else if (pathname === '/time-activity') {
			setSelectedSidebar('time-activity');
		} else if (pathname === '/cost-allocations') {
			setSelectedSidebar('cost-allocations');
		} else if (pathname === '/journal-entries') {
			setSelectedSidebar('journal-entries');
		} else if (pathname === '/reports') {
			setSelectedSidebar('reports');
		} else {
			setSelectedSidebar('dashboard');
		}
	};

	useEffect(() => {
		initialFunctionCall();
	}, [pathname]);

	useEffect(() => {
		const token = localStorage.getItem('accessToken');

		if (token) {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
			navigate('/login');
		}
	}, []);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state?.companies
	);

	useEffect(() => {
		if (userData && !userData.isSuperAdmin) {
			dispatch(fetchClosingDateAction({}));
		}
	}, [selectedCompanyDetails, userData]);

	useEffect(() => {
		if (userData && userData.isSuperAdmin) {
			navigate('/admin');
		}
	}, [userData]);
	useIdleRedirect()

	// JSX
	return !isLoading ? (
		isLoggedIn ? (
			<div className={styles['global-layout']} style={{ overflowY: 'hidden' }}>
				<div className={styles['global-layout__wrapper']}>
					<div className={styles['global-layout__header']}>
						<Header />
					</div>
					<div className={styles['global-layout__body']}>
						{!userData?.isSuperAdmin ? (
							<div
								className={
									styles[
										`global-layout__body--sidebar-${
											isExpanded ? 'hide' : 'show'
										}`
									]
								}
							>
								<Sidebar
									items={finalMenuItems}
									// items={pageMenuItems}
									isGetSupportButton={false}
									handleSidebar={handleSidebar}
									selectedSidebar={selectedSidebar}
								/>
							</div>
						) : null}

						<div
							className={
								styles[
									`global-layout__body--body-${
										isExpanded || userData?.isSuperAdmin
											? 'widthFull'
											: 'widthFixed'
									}`
								]
							}
						>
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		) : null
	) : null;
};

export default GlobalLayout;
