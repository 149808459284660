import { Col, Modal, Row, Button } from 'antd';
import './index.scss';
import { WarningOutlined } from '@ant-design/icons';

interface Props {
	isOpen: boolean;
	employees: string[];
	handleCancel: () => void;
}

const NotFoundEmployeesModal = (props: Props) => {
	const { isOpen, employees, handleCancel } = props;

	return (
		<Modal
			open={isOpen}
			onCancel={handleCancel}
			closable={false}
			footer={null}
			className="time-sheet-validate-modal profile__popup"
			width={800}
		>
			<p style={{ fontWeight: 600, fontSize: '28px', marginBottom: '10px', borderBottom: '1px solid #ccc', paddingBottom: '10px' }} >
				<WarningOutlined/>&nbsp;
				Warning
			</p>
			<p style={{ marginBottom: '10px' }} >
				The following employee(s) will be excluded from the allocation process due to the absence of time activity data or fixed percentage settings. To address this, please update the employee&apos;s time records or fixed percentage settings and recreate the timesheet for this period.
			</p>
			<div className={'warning-container'} >
				{
					employees.map((e, index) => (
						<p key={e} >{index + 1}. {e}</p>
					))
				}
			</div>
			<Row style={{ marginTop: '15px' }}>
				<Col xs={24} md={8} lg={8} sm={8}>
					<Button className={`save`} onClick={handleCancel}>
						Ok
					</Button>
				</Col>
			</Row>
		</Modal>
	);
};

export default NotFoundEmployeesModal;
