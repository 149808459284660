import { monthNames } from 'constants/Data';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classAction } from 'redux/action/classAction';
import { customerOptionsAction } from 'redux/action/customerAction';
import { getEmployeeAction } from 'redux/action/employeeAction';
import { getCustomerSummaryReportAction } from 'redux/action/reportsAction';
import { AppDispatch } from 'redux/store';
import { useDebounce } from 'use-debounce';
import { generateYearRange, getMonthAfter12Months, hasText } from 'utils/utils';
import SearchAndFilter from './SearchAndFilter';
import CustomerSummaryTable from './Table';
import styles from './index.module.scss';

function CustomerSummaryReport() {
	const dispatch = useDispatch<AppDispatch>();

	const [searchValue, setSearchValue] = useState<string>('');
	const [debounceSearchValue] = useDebounce(searchValue, 1000);
	const [applySearch, setApplySearch] = useState<boolean>(false);
	const [sortBy, setSortBy] = useState<string | null>('');
	const [sortType, setSortType] = useState<string | null>('');
	const [yearRange, setYearRange] = useState<any>([]);
	const [yearValue, setYearValue] = useState<any>(yearRange[0]);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const clearFilter = () => {
		setApplySearch(true);
		setSearchValue('');
	};

	const performSearchHandler = (value: string) => {
		setSearchValue(value);
		setApplySearch(true);
	};

	const handleYear = (value: any) => {
		setYearValue(value);
		setApplySearch(true);
	};

	const tableChangeHandler = (_: unknown, __: unknown, tableData: any) => {
		if (tableData.columnKey && tableData.order) {
			setApplySearch(true);
			setSortBy(tableData.columnKey);
			setSortType(tableData.order === 'ascend' ? 'asc' : 'desc');
		}
	};

	const getCustomerReportData = async () => {
		let startYear;
		if (yearValue && yearValue?.split('-').length > 0) {
			startYear = yearValue.split('-')[0]?.split(' ')[1];
		}
		const query: any = {
			limit: 10,
			search: searchValue,
			sort: sortBy,
			type: sortType,
			year: startYear,
		};

		if (!hasText(searchValue)) {
			delete query.search;
		}

		await dispatch(getCustomerSummaryReportAction(query));
	};

	useEffect(() => {
		dispatch(classAction());
		dispatch(customerOptionsAction());
		dispatch(getEmployeeAction());
		getCustomerReportData();
	}, []);

	useEffect(() => {
		if (selectedCompanyDetails) {
			dispatch(classAction());
			dispatch(customerOptionsAction());
			dispatch(getEmployeeAction());
			clearFilter();

			const startMonth = selectedCompanyDetails?.company?.fiscalYear;
			const endMonth = getMonthAfter12Months(
				monthNames.indexOf(selectedCompanyDetails?.company?.fiscalYear) + 1
			);
			const currentYear = new Date().getFullYear();
			const years = generateYearRange(startMonth, endMonth, currentYear, 5);

			const data = years.reverse().map((year) => {
				return {
					key: Math.random(),
					label: year,
					value: year,
				};
			});
			setYearValue(data[0] && data[0]?.value);
			setYearRange(data);
		}
	}, [selectedCompanyDetails]);

	useEffect(() => {
		if (applySearch) {
			getCustomerReportData();
		}
	}, [debounceSearchValue, yearValue, selectedCompanyDetails]);

	return (
		<div>
			<div className={styles['payroll-summary__header']}>
				<SearchAndFilter
					performSearchHandler={performSearchHandler}
					yearHandler={handleYear}
					clearFilter={clearFilter}
					searchValue={searchValue}
					yearValue={yearValue}
					yearRange={yearRange}
				/>
			</div>
			<div className={styles['payroll-summary__table']}>
				<CustomerSummaryTable tableChangeHandler={tableChangeHandler} />
			</div>
		</div>
	);
}

export default CustomerSummaryReport;
