/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, Input, Modal, Radio, Row, Select, TreeSelect } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classAction } from 'redux/action/classAction';
import { customerOptionsAction } from 'redux/action/customerAction';
import { getEmployeeAction } from 'redux/action/employeeAction';
import { postApi, putApi } from 'redux/apis';
import { AppDispatch } from 'redux/store';
import { CloseSvg, DeleteActionSvg } from 'utils/svgs';
import { checkPermission, hasText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
import { payPeriodOptionsAction } from 'redux/action/payPeriodAction';
import { getConfiguration } from 'redux/action/configurationAction';
import { CustomConfiguration } from './configuration';
import { chartOfAccountAction } from 'redux/action/chartOfAccountAction';

interface Props {
	handleCloseModal: () => void;
	isModalOpen: boolean;
	modalData: any;
	getCustomRulesData: (value: string) => void;
	isCopy: boolean | null | undefined;
	payPeriodId: string | null;
}

const configurationIds = ['1', '2', '3'];

const CustomConfigurationAddRuleModal = (props: Props) => {
	const {
		handleCloseModal,
		isModalOpen,
		modalData,
		getCustomRulesData,
		isCopy,
		payPeriodId,
	} = props;

	const { TextArea } = Input;
	const dispatch = useDispatch<AppDispatch>();

	const { optionData: customerList } = useSelector(
		(state: any) => state.customer
	);

	const { data: classList } = useSelector((state: any) => state.class);
	const { data: employeeList } = useSelector((state: any) => state.employees);
	const { data: qbChartOfAccounts, isLoading: qbChartOfAccountsLoading } =
		useSelector((state: any) => state.chartOfAccounts);

	const fetchData = () => {
		dispatch(classAction());
		dispatch(customerOptionsAction());
		dispatch(getEmployeeAction());
		dispatch(payPeriodOptionsAction());
		dispatch(chartOfAccountAction());
	};

	useEffect(() => {
		fetchData();
		if (payPeriodId) {
			dispatch(
				getConfiguration({
					payPeriodId,
				})
			);
		}
	}, [payPeriodId]);

	const [isLoading, setIsLoading] = useState(false);

	const [data, setData] = useState<any>({
		name: '',
		description: '',
		isActive: true,
	});

	const [settingsJson, setSettingsJson] = useState<any>(null);

	const [criteria, setCriteria] = useState({
		employeeId: 'ANY',
		classId: '',
		customerId: '',
		operator1: 'AND',
		operator2: 'AND',
	});

	const [finalCustomerList, setFinalCustomerList] = useState<any>([]);
	const [isError, setIsError] = useState(false);
	const [validation, setValidation] = useState({
		name: false,
		employeeId: false,
		classId: false,
		customerId: false,
	});

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);
	const isAddRulePermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Custom Rules',
		permission: ['add'],
	});

	const isEditRulePermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Custom Rules',
		permission: ['edit'],
	});

	const { data: configurations } = useSelector(
		(state: any) => state?.configuration
	);

	useEffect(() => {
		if (modalData) {
			setData({
				name: modalData?.name,
				description: modalData?.description,
				isActive: modalData?.isActive ? true : false,
				triggerProcess: modalData?.triggerProcess,
			});
			setCriteria(modalData.criteria);
			if (modalData.actions) {
				const _settingsJson = JSON.parse(JSON.stringify(modalData.actions));

				const mainSettingsJson = JSON.parse(
					JSON.stringify(configurations?.settings)
				);

				Object.keys(mainSettingsJson).forEach((masterKey) => {
					if (configurationIds.includes(masterKey)) {
						if (!_settingsJson[masterKey]) {
							_settingsJson[masterKey] = mainSettingsJson[masterKey];
						}

						_settingsJson[masterKey].capMappingTitle =
							mainSettingsJson[masterKey].capMappingTitle;

						Object.keys(mainSettingsJson[masterKey].fields).forEach(
							(fieldKey) => {
								if (!_settingsJson[masterKey].fields[fieldKey]) {
									_settingsJson[masterKey].fields[fieldKey] =
										mainSettingsJson[masterKey].fields[fieldKey];
								}

								_settingsJson[masterKey].fields[fieldKey].label =
									mainSettingsJson[masterKey].fields[fieldKey].label;
								_settingsJson[masterKey].fields[fieldKey].isActive =
									mainSettingsJson[masterKey].fields[fieldKey].isActive;
							}
						);
					}
				});

				setSettingsJson(JSON.parse(JSON.stringify(_settingsJson)));
			} else {
				setSettingsJson(JSON.parse(JSON.stringify(configurations?.settings)));
			}
		} else {
			setSettingsJson(JSON.parse(JSON.stringify(configurations?.settings)));
		}
	}, [modalData, configurations]);

	useEffect(() => {
		if (customerList && customerList.length > 0) {
			const newCustomers = [...customerList];
			newCustomers.shift();
			const finalData = [
				{
					value: '',
					title: 'Blank Customer',
					children: [],
				},
				{
					value: 'ANY',
					title: 'Any Customer',
					children: [],
				},
				...newCustomers,
			];
			setFinalCustomerList(finalData);
		}
	}, [customerList]);

	const handleInput = (e: any) => {
		let value = e.target.value;
		if (e.target.name == 'name') {
			if (!hasText(value)) {
				setValidation((prev) => {
					return {
						...prev,
						name: true,
					};
				});

				setIsError(true);
			} else {
				setValidation((prev) => {
					return {
						...prev,
						name: false,
					};
				});

				setIsError(false);
			}
		}

		if (e.target.name == 'isActive') {
			value = e.target.checked;
		}

		setData((prev: any) => {
			return {
				...prev,
				[e.target.name]: value,
			};
		});
	};

	const handleCriteria = (name: string, value: any) => {
		if (name == 'employeeId') {
			if (!hasText(value)) {
				setValidation((prev) => {
					return {
						...prev,
						employeeId: true,
					};
				});
				setIsError(true);
			} else {
				setValidation((prev) => {
					return {
						...prev,
						employeeId: false,
					};
				});

				setIsError(false);
			}
		}
		setCriteria((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const handleOperator = (value: string, name: string) => {
		setCriteria((prev) => {
			const _query = {
				...prev,
				[name]: value,
			};

			return _query;
		});

		if (name === 'operator1' && value === 'NONE') {
			setCriteria((prev) => {
				return {
					...prev,
					customerId: '',
				};
			});
		}

		if (name === 'operator2' && value === 'NONE') {
			setCriteria((prev) => {
				return {
					...prev,
					classId: '',
				};
			});
		}
	};

	const checkAllValidation = () => {
		let isValidation = true;
		if (!hasText(data.name) && criteria.employeeId === '') {
			setValidation({
				...validation,
				name: true,
				employeeId: true,
			});
			isValidation = false;
		}

		if (!hasText(data.name) && criteria.employeeId != '') {
			setValidation({
				...validation,
				name: true,
			});
			isValidation = false;
		}

		if (!hasText(criteria.employeeId) && hasText(data.name)) {
			setValidation({
				...validation,
				employeeId: true,
			});
			isValidation = false;
		}

		return isValidation;
	};

	const handleSubmit = async () => {
		try {
			const invalidation = checkAllValidation();
			if (invalidation) {
				setIsLoading(true);
				let response: any;
				if (modalData && !isCopy) {
					response = await putApi(
						`/custom-configuration-rule/${modalData.id}`,
						{
							companyId: localStorage.getItem('companyId'),
							payPeriodId,
							...data,
							criteria: criteria,
							actions: settingsJson,
						}
					);
				} else {
					response = await postApi(`/custom-configuration-rule`, {
						companyId: localStorage.getItem('companyId'),
						...data,
						criteria: criteria,
						payPeriodId,
						actions: settingsJson,
					});
				}
				setIsLoading(false);
				toastText(response?.data?.message, 'success');
				handleCloseModal();
				getCustomRulesData(data?.triggerProcess);
			}
		} catch (err: any) {
			if (err.response.data.message) {
				toastText(err.response.data.message, 'error');
			} else {
				toastText('Something went wrong.', 'error');
			}
			setIsLoading(false);
		}
	};

	return (
		<div className={styles['rule-modal-container']}>
			<Modal
				open={isModalOpen}
				onCancel={handleCloseModal}
				className="preview-container"
				closable={false}
				style={{
					borderRadius: 0,
				}}
				footer={[]}
			>
				<div>
					<Row className={styles['preview-container__top']}>
						<div className={styles['preview-container__top-title']}>
							<b> {modalData && !isCopy ? 'Edit' : 'Add'} Custom Rules</b>
						</div>
						<div
							className={styles['preview-container__top-close']}
							onClick={handleCloseModal}
						>
							<CloseSvg />
						</div>
					</Row>
				</div>
				<div
					className={`${styles['preview-container__main']} preview-container__main`}
				>
					<div className={styles['preview-container__main-add-rule']}>
						<div className={styles['preview-container__main-title']}>
							{modalData && !isCopy ? 'Edit' : 'Add'} Rule
						</div>
						<div
							className={styles['preview-container__main-add-rule__content']}
						>
							<div className={styles['preview-container__main-input-multiple']}>
								<div
									className={styles['preview-container__main-input-container']}
								>
									<label className={styles['preview-container__main-label']}>
										Rule Name <span className="text-danger">*</span>
									</label>
									<Input
										className={`
											${
												validation.name
													? styles['preview-container__main-input-error']
													: styles['preview-container__main-input']
											}
										${styles['input-field']}`}

										value={data.name}
										name="name"
										onChange={handleInput}
									/>
								</div>
								<div
									className={`${styles['preview-container__main-input-container']} ${styles['preview-container__main-checkbox']}`}
								>
									<Checkbox
										checked={data.isActive}
										name="isActive"
										onChange={handleInput}
									/>
									<label className={styles['preview-container__main-label']}>
										Is Active
									</label>
								</div>
							</div>

							<div
								className={styles['preview-container__main-input-container']}
							>
								<label className={styles['preview-container__main-label']}>
									Description
								</label>
								<TextArea
									className={`${styles['preview-container__main-input ']} ${styles['input-field']}`}
									rows={2}
									value={data.description}
									name="description"
									onChange={handleInput}
									maxLength={300}
								/>
							</div>
						</div>
					</div>
					<div className={styles['preview-container__main-criteria']}>
						<div className={styles['preview-container__main-title']}>
							Criteria
						</div>
						<div
							className={styles['preview-container__main-add-rule__content']}
						>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label className={styles['preview-container__main-label']}>
									1. Employee Name <span className="text-danger">*</span>
								</label>
								<Select
									className={`${styles['preview-container__main-input']} ${
										validation.employeeId &&
										'preview-container__main-input-error'
									}`}
									value={criteria.employeeId}
									size="large"
									onChange={(value, data: any) => {
										handleCriteria('employeeId', value);
									}}
								>
									<Select.Option value="ANY">Any Employee</Select.Option>
									{employeeList?.map((employee: any, index: number) => {
										return (
											<Select.Option
												value={employee?.id}
												key={index}
											>
												{employee?.fullName}
											</Select.Option>
										);
									})}
								</Select>
							</div>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label
									className={styles['preview-container__main-label']}
								></label>
								<div
									className={`${styles['preview-container__main-input']} ${styles['preview-container__main-operator']}`}
								>
									<button
										className={`${
											styles['preview-container__main-operator-button']
										} ${
											criteria.operator1 === 'AND' &&
											styles['preview-container__main-operator-button-active']
										}`}
										onClick={() => handleOperator('AND', 'operator1')}
									>
										AND
									</button>
								</div>
							</div>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label className={styles['preview-container__main-label']}>
									2. Customer
								</label>
								<TreeSelect
									className={`${styles['preview-container__main-input']} ${
										validation.customerId &&
										'preview-container__main-input-error'
									}`}
									dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
									size="large"
									placeholder="Customer"
									value={criteria.customerId}
									showSearch={true}
									treeData={finalCustomerList}
									treeDefaultExpandAll
									onChange={(value: any) => handleCriteria('customerId', value)}
									filterTreeNode={(inputValue, treeNode) => {
										// Customize the filtering logic here
										return treeNode.props.title
											.toLowerCase()
											.includes(inputValue.toLowerCase());
									}}
								/>
							</div>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label
									className={styles['preview-container__main-label']}
								></label>
								<div
									className={`${styles['preview-container__main-input']} ${styles['preview-container__main-operator']}`}
								>
									<button
										className={`${
											styles['preview-container__main-operator-button']
										} ${
											criteria.operator2 === 'AND' &&
											styles['preview-container__main-operator-button-active']
										}`}
										onClick={() => handleOperator('AND', 'operator2')}
									>
										AND
									</button>
								</div>
							</div>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label className={styles['preview-container__main-label']}>
									3. Class
								</label>
								<Select
									className={`${styles['preview-container__main-input']} ${
										validation.classId && 'preview-container__main-input-error'
									}`}
									value={criteria.classId}
									size="large"
									onChange={(value) => handleCriteria('classId', value)}
								>
									<Select.Option value="">Blank Class</Select.Option>
									<Select.Option value="ANY">Any Class</Select.Option>
									{classList?.map((singleClass: any, index: number) => {
										return (
											<Select.Option
												value={singleClass?.Id}
												key={index}
											>
												{singleClass?.FullyQualifiedName}
											</Select.Option>
										);
									})}
								</Select>
							</div>
							<div
								className={styles['preview-container__main-input-container']}
							>
								<label className={styles['preview-container__main-label']}>
									Criteria Pattern
								</label>
								<p className={styles['preview-container__main-input']}>
									{criteria.employeeId && 1}{' '}
									{criteria.operator1 !== 'NONE' && `${criteria.operator1} 2`}{' '}
									{criteria.operator2 !== 'NONE' && `${criteria.operator2} 3`}
								</p>
							</div>
						</div>
					</div>

					<div className={styles['preview-container__main-add-rule']}>
						<div className={styles['preview-container__main-add-rule_content']}>
							<div className={styles['preview-container__main-title']}>
								Setup Custom Mapping
							</div>

							{settingsJson && qbChartOfAccounts?.length ? (
								<CustomConfiguration
									settingsJson={settingsJson}
									handleChangeSettingsJson={(data) => setSettingsJson(data)}
									accountList={qbChartOfAccounts?.map((singleClass: any) => {
										return { ...singleClass, isDisable: false };
									})}
								/>
							) : null}
						</div>
					</div>

					<div className={styles['preview-container__main-buttons']}>
						<button
							className={styles['preview-container__main-buttons-cancel']}
							onClick={handleCloseModal}
						>
							Cancel
						</button>
						{(isEditRulePermission || isAddRulePermission) && (
							<button
								className={`${styles['preview-container__main-buttons-save']} ${
									(isLoading || isError) && 'pointer-event-none'
								}`}
								onClick={handleSubmit}
							>
								{isLoading ? (
									<img
										src="/assets/gifs/loading-black.gif"
										height={40}
									/>
								) : modalData && !isCopy ? (
									'Update'
								) : (
									'Save'
								)}
							</button>
						)}
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default CustomConfigurationAddRuleModal;
