import { filterQBEntities } from 'utils/utils';
import styles from './index.module.scss';
import { Col, Row, Select } from 'antd';
import { useEffect, useState } from 'react';

interface Props {
    settingsJson: any,
    handleChangeSettingsJson: (data: any) => void,
    accountList: any[],
}

export const CustomConfiguration = (props: Props) => {

    const { settingsJson, accountList, handleChangeSettingsJson } = props

    const [filteredItems, setFilteredItems] = useState<any>([])
    const configurationIds = ['1', '2', '3']

    useEffect(() => {

        const _filteredItems = filterQBEntities(
            settingsJson,
            'qbCoa',
            accountList
        );

        setFilteredItems(_filteredItems);

    }, [accountList, settingsJson])

    const onChangeValue = (masterKey: any, fieldKey: any, valueKey: any, value: any ) => {
        const _settings: any = JSON.parse(JSON.stringify(settingsJson));

        _settings[masterKey].fields[fieldKey][valueKey] = value;

        handleChangeSettingsJson(_settings);
   }

    return (
        <div>
            {
                settingsJson ? (
                    <div>
                        {
                            Object.values(settingsJson).map((singleMappingSection: any, index: number) => {
                                return (
                                    Object.values(singleMappingSection?.fields).filter((e: any) => e.isActive).length && configurationIds?.includes(singleMappingSection?.id) ? (
                                        <div key={index} style={{ marginTop: '20px' }} >
                                            <Row>
                                                <Col xs={8}>
                                                    <div className={styles['preview-container__main-label']}>
                                                        {singleMappingSection?.capMappingTitle}
                                                    </div>
                                                </Col>
                                                <Col xs={8} >
                                                    <div className={styles['preview-container__main-label']}>
                                                        GL Debit Accounts
                                                    </div>
                                                </Col>
                                                <Col xs={8} >
                                                    <div className={styles['preview-container__main-label']}>
                                                        GL Credit Accounts
                                                    </div>
                                                </Col>
                                            </Row>
                                            {
                                                Object.values(singleMappingSection?.fields).map((field: any) => (
                                                    field?.isActive ? (
                                                        <Row key={`${singleMappingSection?.id}${field?.id}`}>
                                                            <Col xs={8} style={{ marginTop: '20px' }} >
                                                                <div>
                                                                    {field?.label}
                                                                </div>
                                                            </Col>
                                                            <Col xs={16} style={{ marginTop: '20px' }} >
                                                                <Row gutter={20}> 
                                                                    <Col xs={12} >
                                                                        <Select
                                                                            placeholder={singleMappingSection?.placeHolder}
                                                                            size="large"
                                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                                            value={field?.value}
                                                                            showSearch
                                                                            filterOption={(input, option: any) =>
                                                                                (option?.children as string)
                                                                                    .toLowerCase()
                                                                                    .indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            onChange={(dataValue) => {
                                                                                onChangeValue(singleMappingSection?.id, field?.id, 'value', dataValue);
                                                                            }}
                                                                            style={{ width: "100%" }}
                                                                        >
                                                                            {filteredItems.map((qbData: any, key: number) => (
                                                                                <Select.Option
                                                                                    value={qbData?.Id}
                                                                                    key={key}
                                                                                    // disabled={(singleMappingSection.id == '1' || singleMappingSection.id == '2' || singleMappingSection.id == '3') ? qbData.isDisable && qbData?.Id !== field.creditValue : qbData.isDisable}
                                                                                >
                                                                                    {qbData?.Name}
                                                                                </Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Col>
                                                                    <Col xs={12} >
                                                                        <Select
                                                                            placeholder={singleMappingSection?.placeHolder}
                                                                            size="large"
                                                                            getPopupContainer={(trigger) => trigger.parentNode}
                                                                            value={field?.creditValue}
                                                                            showSearch
                                                                            filterOption={(input, option: any) =>
                                                                                (option?.children as string)
                                                                                    .toLowerCase()
                                                                                    .indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                            style={{ width: "100%" }}
                                                                            onChange={(dataValue) => {
                                                                                onChangeValue(singleMappingSection?.id, field?.id, 'creditValue', dataValue);
                                                                            }}
                                                                        >
                                                                            {filteredItems.map((qbData: any, key: number) => (
                                                                                <Select.Option
                                                                                    value={qbData?.Id}
                                                                                    key={key}
                                                                                    // ....disabled={(singleMappingSection.id == '1' || singleMappingSection.id == '2' || singleMappingSection.id == '3') ? qbData.isDisable && qbData?.Id !== field.value : qbData.isDisable}
                                                                                >
                                                                                    {qbData?.Name}
                                                                                </Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    ) : null
                                                ))
                                            }
                                        </div>
                                    ) : null
                                );
                            })
                        }
                    </div>
                ) : null
            }
        </div>
    );
}