/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableActionHeader } from 'components/Global';
import styles from './index.module.scss'
import { checkPermission } from 'utils/utils';
import { useSelector } from 'react-redux';
import { CustomMappingRuleTable } from './Table';
import { useState } from 'react';

export const CustomConfigurationMapping = (props: Props) => {

    const { payPeriodId } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const selectedCompanyPermission = useSelector(
        (state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
    );

    const isAddRulePermission = checkPermission(selectedCompanyPermission, {
        permissionName: 'Custom Rules',
        permission: ['add'],
    });

    return (
        <div>
            <TableActionHeader title={'Custom Mapping Rules'}>
                {isAddRulePermission && (
                    <button
                        className={styles['add-rule-button']}
                        onClick={() => setIsModalOpen(true)}
                    >
                        + Add Rule
                    </button>
                )}
            </TableActionHeader>
            <div className={styles['custom__rules-tabs']}>
                <CustomMappingRuleTable
                    payPeriodId={payPeriodId}
                    isModalOpen={isModalOpen}
                    handleModalClose={(flag: boolean) => setIsModalOpen(flag)}
                />
            </div>
        </div>
    )

}

interface Props {
    payPeriodId: string | null
}