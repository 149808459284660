import { createSlice } from '@reduxjs/toolkit';
import {
	syncLogsAction,
	syncLogsPaginateAction,
} from 'redux/action/syncLogsAction';
const initialState = {
	isLoading: true,
	isFirstTimeLoading: true,
	data: [],
	error: null,
	count: 0,
};

const syncLogsSlice = createSlice({
	initialState,
	name: 'syncLogs',
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(syncLogsAction.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(syncLogsAction.fulfilled, (state, action) => {
			state.data = action.payload.data.data;
			state.count = action.payload.data.count;
			state.isFirstTimeLoading = false;
			state.isLoading = false;
			state.error = null;
		});
		builder.addCase(syncLogsAction.rejected, (state: any, action) => {
			state.error = action.payload;
			state.isFirstTimeLoading = false;
			state.isLoading = false;
			state.data = [];
		});
		//Paginate slice
		builder.addCase(syncLogsPaginateAction.fulfilled, (state: any, action) => {
			state.data = [...state.data, ...action.payload.data.data];
			state.isLoading = false;
		});
	},
});

export default syncLogsSlice;
