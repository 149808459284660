import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { getApi } from 'redux/apis';
import { formatNumberWithCommasV2, toastText } from 'utils/utils';
import styles from './index.module.scss';
import GraphModal from '../Modal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const SalaryExpenseByMonth = (props: any) => {
	const { year } = props;
	const navigate = useNavigate();

	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: '',
				data: [],
				borderColor: '#9CB891',
				fill: false,
			},
		],
	});

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const [maxNumber, setMaxNumber] = useState(0);

	const fetchData = async () => {
		try {
			let startYear;
			if (year && year?.split('-').length > 0) {
				startYear = year.split('-')[0]?.split(' ')[1];
			}
			const response = await getApi('/dashboard/salary-by-month', {
				companyId: localStorage.getItem('companyId'),
				year: startYear,
				// year: year ? year['$y'] : currentYear.getFullYear(),
			});

			const updatedChartData = {
				labels: response?.data?.data?.labels,
				datasets: [
					{
						label: '',
						data: response?.data?.data?.data,
						borderColor: '#9CB891',
						fill: false,
					},
				],
			};
			setMaxNumber(response?.data?.data?.max);
			setChartData(updatedChartData);
		} catch (err: any) {
			if (err?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			} else {
				toastText(
					'Something went wrong in fetching salary expense by month',
					'error'
				);
			}
		}
	};

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleOpen = () => {
		setIsModalOpen(true);
	};

	useEffect(() => {
		fetchData();
	}, [year, selectedCompanyDetails]);

	return (
		<>
			<div className={styles['salary-expense-main']}>
				<div className={styles['salary-expense-main__header']}>
					Payroll Expense by Pay Period
				</div>
				<div style={{ height: '100%' }}>
					<Line
						onClick={handleOpen}
						data={chartData}
						options={{
							responsive: true,
							scales: {
								y: {
									beginAtZero: true,
									max: Number(maxNumber),
									ticks: {
										callback: (tickValue) => {
											return `$${formatNumberWithCommasV2(tickValue)}`;
										},
										font: {
											size: 12,
										},
										color: 'black',
										maxTicksLimit: 5,
									},
								},
								x: {
									offset: true,
									grid: {
										display: false,
									},
									ticks: {
										color: 'black',
									},
								},
							},
							plugins: {
								legend: {
									display: false,
								},
								tooltip: {
									enabled: true,
									callbacks: {
										label: (tooltipItem: any) => {
											return `$${formatNumberWithCommasV2(tooltipItem.raw)}`;
										},
									},
								},
							},
						}}
					/>
				</div>
			</div>
			{isModalOpen && (
				<GraphModal
					handleCancel={handleClose}
					isModalOpen={isModalOpen}
					chartData={chartData}
					chartType="lineChart"
					maxNumber={maxNumber}
					displayTitle="Salary Expense by Pay Period"
				/>
			)}
		</>
	);
};

export default SalaryExpenseByMonth;
