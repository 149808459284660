import { Table } from 'antd';
import { useState, useEffect } from 'react';
import './index.scss';
import { useSelector } from 'react-redux';
import { NumericFormat } from 'react-number-format';

const PayrollSummaryTable = () => {
	const { Column } = Table;
	const [columns, setColumns] = useState([]);

	const { configurations: configurationsData } = useSelector(
		(state: any) => state.companies
	);

	const { payRollSummaryData, isPayrollSummaryLoading } = useSelector(
		(state: any) => state.reports
	);

	const { data: sectionWiseFields } = useSelector(
		(state: any) => state?.employeeCostColumns
	);

	useEffect(() => {
		const finalColumns: any = [
			{
				title: 'Employee Name',
				dataIndex: 'employee-name',
				key: Math.random(),
				type: 'string',
			},
			{
				title: 'Allocation%',
				dataIndex: 'allocation',
				key: Math.random(),
				type: 'string',
			},
		];
		sectionWiseFields?.map((singleSection: any) => {
			singleSection?.fields?.map((singleField: any) => {
				if (
					singleSection.no != 0 &&
					singleField.isActive &&
					singleField.jsonId.startsWith('f')
				) {
					finalColumns.push({
						title: singleField?.name,
						dataIndex: singleField?.id,
						key: singleField?.id,
						type: 'number',
					});
				}
			});
		});

		finalColumns.push({
			title: 'Total',
			dataIndex: 'total',
			key: Math.random(),
			type: 'number',
		});

		setColumns(finalColumns);
	}, [sectionWiseFields]);

	return (
		<div className="payroll-summary-report">
			<Table
				dataSource={payRollSummaryData}
				scroll={{ y: '43vh' }}
				pagination={false}
				className="table-global payroll-summary-report-table"
				rowKey={(record: any) => {
					return record.id;
				}}
				style={{
					overflowY: 'auto',
				}}
				rowClassName={(record: any) => {
					if (record.type === 'grandTotal') {
						return 'journal-total-row';
					}
					return '';
				}}
				loading={isPayrollSummaryLoading}
			>
				{columns?.map((column: any) => {
					return (
						<Column
							title={column.title}
							dataIndex={column.dataIndex}
							key={column.dataIndex}
							className={`bg-white`}
							align={column.type === 'number' ? 'right' : 'left'}
							render={(value: string) => {
								const type = column.type;

								return type === 'number' ? (
									<NumericFormat
										value={value}
										thousandSeparator=","
										decimalScale={configurationsData?.decimalToFixedAmount || 2}
										fixedDecimalScale={true}
										displayType="text"
										prefix="$"
									/>
								) : (
									value
								);
							}}
							width="200px"
						/>
					);
				})}
			</Table>
		</div>
	);
};

export default PayrollSummaryTable;
