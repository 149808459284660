import { createSlice } from '@reduxjs/toolkit';
import {
	getJournalListingAction,
	getJournalPaginateAction,
	previewJournalAction,
} from 'redux/action/journalAction';
const initialState: any = {
	isLoading: true,
	isFirstTimeLoading: true,
	journalData: [],
	error: null,
	journalListingLoading: false,
	journalListingData: [],
	journalListingCount: 0,
	journalListingError: null,
};

const journalSlice = createSlice({
	initialState,
	name: 'journal',
	reducers: {
		clearCostAllocationRedux: () => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(previewJournalAction.pending, (state) => {
			state.isLoading = true;
			state.journalData = null;
		});
		builder.addCase(previewJournalAction.fulfilled, (state, action) => {
			state.journalData = action.payload.data;
			state.isFirstTimeLoading = false;
			state.isLoading = false;
			state.error = null;
		});
		builder.addCase(previewJournalAction.rejected, (state: any, action) => {
			state.error = action.payload;
			state.isFirstTimeLoading = false;
			state.isLoading = false;
			state.journalData = [];
		});
		builder.addCase(getJournalListingAction.pending, (state) => {
			state.journalListingLoading = true;
			state.journalListingData = [];
		});
		builder.addCase(getJournalListingAction.fulfilled, (state, action) => {
			state.journalListingData = action.payload.data?.journals;
			state.journalListingCount = action.payload.data?.count;
			state.journalListingLoading = false;
			state.journalListingError = null;
		});
		builder.addCase(getJournalListingAction.rejected, (state: any, action) => {
			state.journalListingError = action.payload;
			state.journalListingLoading = false;
			state.journalListingData = [];
		});
		builder.addCase(
			getJournalPaginateAction.fulfilled,
			(state: any, action) => {
				state.journalListingData = [
					...state.journalListingData,
					...action.payload.data.journals,
				];
				state.isLoading = false;
			}
		);
	},
});

export default journalSlice;
