import { TableActionHeader } from 'components/Global';
import SubscriptionDetails from './Details';

const Subscription = () => {
    return (
        <div>
            <TableActionHeader title={'Subscription'}></TableActionHeader>
            <SubscriptionDetails/>
        </div>
    )
}

export default Subscription;