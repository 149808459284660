import { SearchOutlined } from '@ant-design/icons';
import { Col, DatePicker, Input, Row, Space, TreeSelect } from 'antd';
import ClearFilter from 'components/Global/ClearFilter';
import ExportData from 'components/Global/ExportData';
import PayPeriodFilter from 'components/Global/PayPeriodFilter';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';

interface Props {
	handlePayPeriod: (payPeriodId: string) => void;
	selectedPayPeriod: string | null;
	performFilterHandler: (key: string, value: string) => void;
	performSearchHandler: (value: string) => void;
	searchValue: string;
	filterValue: any;
	clearFilter: () => void;
	yearHandler: (year: any) => void;
	yearValue: any;
}

const SearchAndFilter = (props: Props) => {
	const {
		selectedPayPeriod,
		handlePayPeriod,
		clearFilter,
		performFilterHandler,
		performSearchHandler,
		filterValue,
		searchValue,
		yearHandler,
		yearValue,
	} = props;

	const { timeSummaryData } = useSelector((state: any) => state.reports);
	const { optionData: customer } = useSelector((state: any) => state.customer);

	return (
		<div className={styles['search-filter']}>
			<div className={`${styles['search-filter__wrapper']}`}>
				<Row justify="space-between">
					<Col>
						<Space>
							<Input
								className={styles['search-filter__search']}
								placeholder="Search here..."
								suffix={<SearchOutlined />}
								onChange={(e) => {
									performSearchHandler(e.target.value);
								}}
								value={searchValue}
								size="large"
							/>

							<TreeSelect
								className={styles['search-filter__filter']}
								dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
								size="large"
								placeholder="Customer"
								value={filterValue?.customerId}
								showSearch
								onChange={(value) => performFilterHandler('customerId', value)}
								treeData={customer}
								treeDefaultExpandAll
								filterTreeNode={(inputValue, treeNode) => {
									// Customize the filtering logic here
									return treeNode.props.title
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
							/>

							<Col span={4}>
								<Space>
									<DatePicker
										className={styles['search-filter__search']}
										size="large"
										picker="year"
										onChange={yearHandler}
										value={yearValue}
										clearIcon={false}
									/>
								</Space>
							</Col>

							<PayPeriodFilter
								payPeriodId={selectedPayPeriod}
								onChangePayPeriodId={(value) => {
									handlePayPeriod(value);
								}}
							/>
							<ClearFilter clearFilter={clearFilter} />
						</Space>
					</Col>
					{timeSummaryData?.timeActivitySummary &&
						timeSummaryData?.timeActivitySummary.length > 0 && (
							<Col>
								<Space className="search-filter-export">
									<ExportData
										moduleName="timeSummaryReport"
										params={{
											payPeriodId: selectedPayPeriod,
											filterValue,
											searchValue,
										}}
									/>
								</Space>
							</Col>
						)}
				</Row>
			</div>
		</div>
	);
};

export default SearchAndFilter;
