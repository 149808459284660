import { SearchOutlined } from '@ant-design/icons';
// import type { DatePickerProps } from 'antd';
import { Col, Input, Row, Select, Space, TreeSelect } from 'antd';
// import dayjs from 'dayjs';
// import moment from 'moment';
import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import './index.scss';
import { SearchAndFilterProps } from './types';
import PayPeriodFilter from 'components/Global/PayPeriodFilter';
import ExportData from 'components/Global/ExportData';
import ClearFilter from 'components/Global/ClearFilter';
import { createModifiedArray } from 'utils/utils';

// For search filter and paginate
const SearchAndFilter: FC<SearchAndFilterProps> = (props) => {
	// Init
	const {
		performFilterHandler,
		performSearchHandler,
		onChangePayPeriod,
		searchValue,
		filterValue,
		selectedPayPeriod,
		sortBy,
		sortType,
		clearFilter,
	} = props;

	const { optionData: customer } = useSelector((state: any) => state.customer);
	const { optionData: classList } = useSelector((state: any) => state.class);
	const { data: employees } = useSelector((state: any) => state.employees);
	const { costAllocationData } = useSelector(
		(state: any) => state?.costAllocation
	);

	const { data: configurations } = useSelector(
		(state: any) => state?.configuration
	);

	const [filteredClassList, setFilteredClassList] = useState(classList);

	useEffect(() => {
		if (
			configurations &&
			configurations.settings &&
			classList &&
			classList.length
		) {
			const _classList = createModifiedArray(
				classList,
				configurations.settings[0].fields['f1'].value
			);
			setFilteredClassList(_classList);
		}
	}, [configurations, classList]);

	const handleChangeSearchValue = (value: any) => {
		performSearchHandler && performSearchHandler(value);
	};

	// JSX
	return (
		<div className={styles['search-filter']}>
			<div
				className={`${styles['search-filter__wrapper']} search-filter-custom cost-allocation-search-filter`}
			>
				<Row justify="space-between">
					<Col>
						<Space>
							<Input
								className={styles['search-filter__search']}
								placeholder="Search here..."
								suffix={<SearchOutlined />}
								onChange={(e) => {
									handleChangeSearchValue(e.target.value);
								}}
								value={searchValue}
								size="large"
							/>
							<Select
								className={styles['search-filter__filter']}
								style={{ width: 200 }}
								onChange={(value) => performFilterHandler('employeeId', value)}
								size="large"
								placeholder="Employee"
								value={filterValue?.employeeId}
								showSearch
								filterOption={(input, option: any) =>
									(option?.children as string)
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
							>
								<Select.Option value="">Select Employee</Select.Option>
								{employees?.map((employee: any, index: number) => {
									return (
										<Select.Option value={employee?.id} key={index}>
											{employee?.fullName}
										</Select.Option>
									);
								})}
							</Select>
							<TreeSelect
								className={styles['search-filter__filter']}
								dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
								size="large"
								placeholder="Customer"
								value={filterValue?.customerId}
								showSearch
								onChange={(value) => performFilterHandler('customerId', value)}
								treeData={customer}
								treeDefaultExpandAll
								filterTreeNode={(inputValue, treeNode) => {
									// Customize the filtering logic here
									return treeNode.props.title
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
							/>
							{/* <Select
								className={styles['search-filter__filter']}
								style={{ width: 200 }}
								onChange={(value) => performFilterHandler('customerId', value)}
								size="large"
								placeholder="Customer"
								value={filterValue?.customerId}
								showSearch
								filterOption={(input, option: any) =>
									(option?.children as string)
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
							>
								<Select.Option value="">Select Customer</Select.Option>
								{customer?.map((singleCustomer: any, index: number) => {
									return (
										<Select.Option value={singleCustomer?.Id} key={index}>
											{singleCustomer?.DisplayName}
										</Select.Option>
									);
								})}
							</Select> */}
							<TreeSelect
								showSearch
								className={styles['search-filter__filter']}
								style={{ width: 200 }}
								onChange={(value) => {
									performFilterHandler('classId', value);
								}}
								value={filterValue?.classId}
								placeholder="Class"
								allowClear={false}
								treeDefaultExpandAll
								size="large"
								treeData={filteredClassList}
								filterTreeNode={(inputValue, treeNode) => {
									// Customize the filtering logic here
									return treeNode.props.title
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
							/>
							{/* <Select
								className={styles['search-filter__filter']}
								style={{ width: 200 }}
								onChange={(value) => performFilterHandler('classId', value)}
								size="large"
								placeholder="Class"
								value={filterValue?.classId}
								showSearch
								filterOption={(input, option: any) =>
									(option?.children as string)
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}
							>
								<Select.Option value="">Select Class</Select.Option>
								{filteredClassList?.map((singleClass: any, index: number) => {
									return (
										<Select.Option value={singleClass?.Id} key={index}>
											{singleClass?.FullyQualifiedName}
										</Select.Option>
									);
								})}
							</Select> */}
							<PayPeriodFilter
								payPeriodId={selectedPayPeriod}
								onChangePayPeriodId={(value) => {
									onChangePayPeriod(value);
								}}
							/>
							<ClearFilter clearFilter={clearFilter} />
						</Space>
					</Col>
					<Col>
						<Space className="search-filter-export">
							{costAllocationData && costAllocationData.length > 0 && (
								<ExportData
									moduleName="costAllocations"
									params={{
										payPeriodId: selectedPayPeriod,
										filterValue,
										searchValue,
										sort: sortBy,
										type: sortType,
									}}
								/>
							)}
						</Space>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default SearchAndFilter;
