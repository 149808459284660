import SuperAdminTable from 'components/SuperAdmin/Table';
import React from 'react';

const SuperAdmin = () => {
	return (
		<div>
			<SuperAdminTable />
		</div>
	);
};

export default SuperAdmin;
