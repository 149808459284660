import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi } from 'redux/apis';

interface GetClosingDateParams {
	syncLogs?: boolean;
}


export const fetchClosingDateAction = createAsyncThunk(
	'closingDate/get',
	async (params: GetClosingDateParams = {}, { rejectWithValue }) => {
		try {
			const response = await getApi(`/quickbooks/closingDate`, {
				companyId: localStorage.getItem('companyId'),
				...params,
			});
			return response.data;
		} catch (error: any) {
			return rejectWithValue(error?.response?.data);
		}
	}
);
