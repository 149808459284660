/* eslint-disable @typescript-eslint/no-unused-vars */
import { SearchOutlined } from '@ant-design/icons';
import { Col, DatePicker, Input, Row, Space } from 'antd';
import styles from './index.module.scss';

import { Select } from 'antd';

interface Props {
	performSearchHandler: (value: string) => void;
	yearHandler: (value: any) => void;
	searchValue: string;
	yearValue: any;
	clearFilter: () => void;
	yearRange: any;
}

const SearchAndFilter = (props: Props) => {
	const {
		performSearchHandler,
		searchValue,
		yearHandler,
		yearValue,
		yearRange,
	} = props;

	return (
		<div className={styles['search-filter']}>
			<div className={`${styles['search-filter__wrapper']}`}>
				<Row gutter={8}>
					<Col span={4}>
						<Space>
							<Input
								className={styles['search-filter__search']}
								placeholder="Search here..."
								suffix={<SearchOutlined />}
								onChange={(e) => {
									performSearchHandler(e.target.value);
								}}
								value={searchValue}
								size="large"
							/>
						</Space>
					</Col>
					<Col span={4}>
						<Space>
							<Select
								style={{ width: 280 }}
								size="large"
								value={yearValue}
								onChange={yearHandler}
								options={yearRange}
							/>
							{/* <DatePicker
								className={styles['search-filter__search']}
								size="large"
								picker="year"
								onChange={yearHandler}
								value={yearValue}
								clearIcon={false}
							/> */}
						</Space>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default SearchAndFilter;
