import { useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutAction } from 'redux/slice/loginSlice';
import { AppDispatch } from 'redux/store';

const useIdleRedirect = () => {
	const dispatch = useDispatch<AppDispatch>();
	const timeout = 900000;
	const redirectPath = '/login';
	// 900000ms = 15 minutes
	const navigate = useNavigate();
	const timerId = useRef<NodeJS.Timeout | null>(null);

	const resetTimer = useCallback(() => {
		if (timerId.current) {
			clearTimeout(timerId.current);
		}
		timerId.current = setTimeout(() => {
			dispatch(logoutAction() as any)
				.unwrap()
				.then(() => {
					localStorage.clear();
					navigate('/login');
				})
				.catch(() => {
					navigate(redirectPath);
					localStorage.clear();
				});
		}, timeout);
	}, [navigate, timeout, redirectPath]);

	useEffect(() => {
		const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];

		const eventHandler = () => resetTimer();

		events.forEach((event) => window.addEventListener(event, eventHandler));

		resetTimer();

		return () => {
			if (timerId.current) {
				clearTimeout(timerId.current);
			}
			events.forEach((event) =>
				window.removeEventListener(event, eventHandler)
			);
		};
	}, [resetTimer]);

	return null;
};

export default useIdleRedirect;
