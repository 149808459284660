import JournalEntriesMain from 'components/JournalEntries/JournalEntriesMain';
import styles from './index.module.scss';
import { useEffect } from 'react';
import { checkPermission } from 'utils/utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const JournalEntries = () => {
	const navigate = useNavigate();

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const isJournalEntriesPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Journals Entries',
			permission: ['view'],
		}
	);

	useEffect(() => {
		document.title = 'CostAllocation Pro - Journals Entries';
	}, []);

	useEffect(() => {
		if (!isJournalEntriesPermission && selectedCompanyPermission) {
			navigate('/unauthorized');
		}
	}, [isJournalEntriesPermission, selectedCompanyPermission]);

	return (
		<div className={styles['journal-entries-main']}>
			<div className={styles['journal-entries-main__title']}>
				Journal Entries
			</div>
			<JournalEntriesMain />
		</div>
	);
};

export default JournalEntries;
