import { getApi } from 'redux/apis';
import DisconnectModal from '../DisconnectModal';
import './index.scss';
import { toastText } from 'utils/utils';
import { useState } from 'react';

export default function NewConnectionModel({ open, onCancel }: Props) {
	const [isLoading, setIsLoading] = useState(false);

	const handleCancel = () => {
		onCancel && onCancel();
	};

	const connectionHandler = () => {
		setIsLoading(true);
		localStorage.setItem('isConnectionExisting', 'false');

		getApi('/quickbooks/authurl')
			.then((res) => {
				setIsLoading(false);
				window.open(res.data.data, '_self');
			})
			.catch(() => {
				setIsLoading(false);
				toastText('Something went wrong', 'error');
			});
	};

	return (
		<>
			<DisconnectModal
				isModalOpen={open}
				handleOk={connectionHandler}
				handleCancel={handleCancel}
				text="Are you sure, you want to Reconnect?"
				buttonText="Yes"
				buttonColor="connect"
				image="assets/images/quickbooks.png"
				// image="assets/images/connect-image.png"
				isLoading={isLoading}
				isReconnect={true}
			/>
		</>
	);
}

interface Props {
	open: boolean;
	onCancel: () => void;
}
