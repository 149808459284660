import { Button, Col, Form, Modal, Radio, Row, TreeSelect } from 'antd';
import { CloseSvg } from 'utils/svgs';
import styles from './index.module.scss';
import './index.scss';

interface Props {
	isModalOpen: boolean;
	handleCancel: () => void;
	batchHandler: (name: string, value: string) => void;
	batchValues: any;
	classList: any;
	customerList: any;
	batchEditHandler: () => void;
	error: boolean;
	isLoading: boolean;
	isCustomerEdit: boolean;
	isClassEdit: boolean;
	setIsCustomerEdit: any;
	setIsClassEdit: any;
}

const BatchEditModal = (props: Props) => {
	const {
		isModalOpen,
		handleCancel,
		batchHandler,
		batchValues,
		classList,
		customerList,
		batchEditHandler,
		error,
		isLoading,
		isCustomerEdit,
		isClassEdit,
		setIsClassEdit,
		setIsCustomerEdit,
	} = props;
	const [form] = Form.useForm();

	const onChange = (e: any) => {
		if (e.target.value === 'Class') {
			setIsClassEdit(true);
			setIsCustomerEdit(false);
		} else {
			setIsCustomerEdit(true);
			setIsClassEdit(false);
		}
	};

	return (
		<div>
			<Modal
				open={isModalOpen}
				onCancel={handleCancel}
				closable={false}
				footer={null}
				className="time-sheet-modal profile__popup"
			>
				<Form
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: 600 }}
					autoComplete="off"
					form={form}
				>
					<Row className={styles['time-sheet-modal__header']}>
						<div className="userDetailsTitle">
							<b>Edit selected time activities</b>
						</div>
						<div
							className={styles['time-sheet-modal__header-delete']}
							onClick={handleCancel}
						>
							<CloseSvg />
						</div>
					</Row>

					<hr />
					<div style={{ marginTop: '10px', padding: '1rem 3rem ' }}>
						<Radio.Group
							options={['Class', 'Customer']}
							onChange={onChange}
							value={isClassEdit ? 'Class' : 'Customer'}
						/>
					</div>
					{isCustomerEdit && (
						<Row className={styles['search-filter__batch']}>
							<TreeSelect
								className={styles['search-filter__filter']}
								showSearch
								style={{ width: '100%' }}
								value={batchValues?.customerId}
								dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
								placeholder="Customer"
								allowClear={false}
								treeDefaultExpandAll
								onChange={(value, record: any) => {
									batchHandler('customerId', value);
									batchHandler('customerName', record[0]);
								}}
								size="large"
								treeData={customerList.map((item: any) => {
									if (item.title === 'Select Customer') {
										return {
											...item,
											title: 'Blank Customer',
										};
									}
									return item;
								})}
								filterTreeNode={(inputValue, treeNode) => {
									// Customize the filtering logic here
									return treeNode.props.title
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
							/>
						</Row>
					)}
					{isClassEdit && (
						<Row className={styles['search-filter__batch']}>
							<TreeSelect
								showSearch
								style={{ width: '100%' }}
								className={styles['search-filter__filter']}
								onChange={(value, record: any) => {
									batchHandler('classId', value);
									batchHandler('className', record[0]);
								}}
								value={batchValues?.classId}
								placeholder="Class"
								allowClear={false}
								treeDefaultExpandAll
								size="large"
								treeData={classList.map((item: any) => {
									if (item.title === 'Select Class') {
										return {
											...item,
											title: 'Blank Class',
										};
									}
									return item;
								})}
								filterTreeNode={(inputValue, treeNode) => {
									// Customize the filtering logic here
									return treeNode.props.title
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
							/>
							{error && (
								<p className="error-message">Customer or Class is required.</p>
							)}
						</Row>
					)}
					<hr />
					<div className="time_sheet_modal_footer">
						<Row
							justify="start"
							className={styles['time-sheet-modal__body__buttons']}
							gutter={16}
						>
							<Col
								xs={12}
								md={7}
								lg={7}
								sm={8}
							>
								<Button
									className={`${styles['time-sheet-modal__body__send-email']} ${
										isLoading && 'pointer-event-none'
									}`}
									block={true}
									onClick={batchEditHandler}
								>
									{isLoading ? (
										<img
											src="/assets/gifs/loading-black.gif"
											height={40}
										/>
									) : (
										<div
											className={
												styles['time-sheet-modal__body__send-email-inside']
											}
										>
											<p>Edit</p>
										</div>
									)}
								</Button>
							</Col>
							<Col
								xs={12}
								md={7}
								lg={7}
								sm={8}
							>
								<Button
									className={`${styles['time-sheet-modal__body__send-email']}`}
									block={true}
									onClick={handleCancel}
								>
									Cancel
								</Button>
							</Col>
						</Row>
					</div>
				</Form>
			</Modal>
		</div>
	);
};

export default BatchEditModal;
