import { CostAllocationMain } from 'components/CostAllocation';
import styles from './index.module.scss';
import { AppDispatch } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { updateLayOut } from 'redux/slice/layoutExpandSlice';
import { LayoutExpandInterFace } from './types';
import { MaximizeIcon, MinimizeIcon } from 'utils/svgs';
import { useEffect, useState } from 'react';
import { checkPermission, getLastDateOfMonth } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import PreviewJournal from 'components/JournalEntries/PreviewJournal';
import { payPeriodOptionsAction } from 'redux/action/payPeriodAction';
import { Tabs } from 'antd';
import JournalEntriesMain from 'components/JournalEntries/JournalEntriesMain';

export default function CostAllocationPage() {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const { isExpanded } = useSelector(
		(state: LayoutExpandInterFace) => state?.layoutExpand
	);

	const [isPreviewJournalOpen, setIsPreviewJournalOpen] = useState(false);
	const [payPeriodId, setPayPeriodId] = useState<string | null>(null);
	const [payPeriodLastDate, setPayPeriodLastDate] = useState<
		string | Date | null
	>(null);
	const [payPeriodData, setPayPeriodData] = useState(null);
	const [isCostAllocation, setIsCostAllocation] = useState('1');

	const onScreenChange = () => {
		dispatch(updateLayOut({ isExpanded: !isExpanded }));
	};

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const costAllocationData = useSelector(
		(state: any) => state?.costAllocation?.costAllocationData
	);

	const payPeriods = useSelector((state: any) => state?.payPeriods?.optionData);

	const isViewCostAllocationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Cost Allocations',
			permission: ['view'],
		}
	);

	const isJournalEntriesPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Journals Entries',
			permission: ['view'],
		}
	);

	useEffect(() => {
		if (!isViewCostAllocationPermission && isJournalEntriesPermission) {
			setIsCostAllocation('2');
		}
		if (
			!isViewCostAllocationPermission &&
			!isJournalEntriesPermission &&
			selectedCompanyPermission
		) {
			navigate('/unauthorized');
		}
	}, [
		isViewCostAllocationPermission,
		isJournalEntriesPermission,
		selectedCompanyPermission,
	]);

	useEffect(() => {
		document.title = 'CostAllocation Pro - Cost Allocations';
		dispatch(payPeriodOptionsAction());

		return () => {
			dispatch(updateLayOut({ isExpanded: false }));
		};
	}, []);

	useEffect(() => {
		if (payPeriodId) {
			const selectedPayPeriod = payPeriods.find(
				(singlePayPeriod: any) => singlePayPeriod.id === payPeriodId
			);
			setPayPeriodData(selectedPayPeriod);
			const lastDate = getLastDateOfMonth(selectedPayPeriod?.endDate);
			setPayPeriodLastDate(lastDate);
		}
	}, [payPeriods, payPeriodId]);

	const handleOpenPreviewJournal = () => {
		setIsPreviewJournalOpen(true);
	};

	const handleClosePreviewJournal = () => {
		setIsPreviewJournalOpen(false);
		setIsCostAllocation('2');
	};

	const handlePayPeriodId = (payPeriodId: string) => {
		setPayPeriodId(payPeriodId);
	};

	let items;

	if (isViewCostAllocationPermission && isJournalEntriesPermission) {
		items = [
			{
				key: '1',
				label: 'Cost Allocation',
				children: (
					<div className={styles['table-cost-allocation']}>
						<CostAllocationMain handlePayPeriodId={handlePayPeriodId} />
					</div>
				),
			},
			{
				key: '2',
				label: 'Journal Entries',
				children: <JournalEntriesMain />,
			},
		];
	} else if (isViewCostAllocationPermission && !isJournalEntriesPermission) {
		items = [
			{
				key: '1',
				label: 'Cost Allocation',
				children: (
					<div className={styles['table-cost-allocation']}>
						<CostAllocationMain handlePayPeriodId={handlePayPeriodId} />
					</div>
				),
			},
		];
	} else if (!isViewCostAllocationPermission && isJournalEntriesPermission) {
		items = [
			{
				key: '2',
				label: 'Journal Entries',
				children: <JournalEntriesMain />,
			},
		];
	}

	const changeCostAllocationTab = (isCostAllocation: boolean) => {
		if (isCostAllocation) {
			setIsCostAllocation('1');
		} else {
			setIsCostAllocation('2');
		}
	};

	return (
		<>
			<div className={styles['cost-allocation-container']}>
				<div className={styles['cost-allocation-container__header']}>
					<div className={styles['cost-allocation-container__header--title']}>
						Cost Allocations
					</div>
					<div className={styles['cost-allocation-container__header--actions']}>
						{isCostAllocation == '1' && (
							<button
								onClick={() => onScreenChange()}
								className={
									styles[
										'cost-allocation-container__header--actions-layoutExpand'
									]
								}
							>
								{isExpanded ? (
									<>
										<MinimizeIcon /> Minimize
									</>
								) : (
									<>
										<MaximizeIcon /> Maximize
									</>
								)}
							</button>
						)}
						{costAllocationData &&
							costAllocationData.length > 0 &&
							isJournalEntriesPermission && (
								<button
									className={
										styles['cost-allocation-container__header--actions-preview']
									}
									onClick={handleOpenPreviewJournal}
								>
									Preview Journal
								</button>
							)}
					</div>
				</div>
				<div className={styles['cost-allocation-container__table']}>
					<div className={styles['cost-allocation-main']}>
						<Tabs
							defaultActiveKey="2"
							items={items}
							activeKey={isCostAllocation}
							key={isCostAllocation}
							tabBarStyle={{
								paddingLeft: '20px',
								fontSize: '18px',
							}}
							onChange={(e) => {
								changeCostAllocationTab(e === '1');
							}}
						/>
					</div>
				</div>
			</div>
			{isPreviewJournalOpen && (
				<PreviewJournal
					handleClosePreviewJournal={handleClosePreviewJournal}
					isPreviewJournalOpen={isPreviewJournalOpen}
					payPeriodLastDate={payPeriodLastDate}
					payPeriodId={payPeriodId}
					payPeriodData={payPeriodData}
				/>
			)}
		</>
	);
}
