import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './index.scss';

const TimeSummaryTable = () => {
	const { Column } = Table;
	const [columns, setColumns] = useState([]);

	const { timeSummaryData, isTimeSummaryLoading } = useSelector(
		(state: any) => state.reports
	);
	// const { timeSummaryData, isTimeSummaryLoading, timeSummaryError } =
	// 	useSelector((state: any) => state.reports);

	useEffect(() => {
		if (timeSummaryData?.classNames && timeSummaryData.classNames?.length) {
			const finalColumns = timeSummaryData.classNames.map(
				(timeSummary: any) => {
					return {
						title: timeSummary,
						dataIndex: timeSummary,
					};
				}
			);

			const _columns: any = [
				{
					title: 'Employee Name',
					dataIndex: 'name',
				},
				...finalColumns,
				{
					title: 'Grand Total',
					dataIndex: 'totalHours',
				},
			];
			setColumns(_columns);
		}
	}, [timeSummaryData]);

	return (
		<div className="time-summary-report">
			<Table
				dataSource={timeSummaryData?.timeActivitySummary}
				scroll={{ y: '43vh' }}
				pagination={false}
				className="table-global time-summary-report-table"
				rowKey={(record: any) => {
					return record.id;
				}}
				style={{
					overflowY: 'auto',
				}}
				rowClassName={(record: any) => {
					if (record.name === 'Total') {
						return 'journal-total-row';
					}
					return '';
				}}
				loading={isTimeSummaryLoading}
			>
				{columns?.map((column: any) => {
					return (
						<Column
							title={column.title}
							dataIndex={column.dataIndex}
							key={column.dataIndex}
							className={`bg-white`}
							render={(value: string) => value}
							width="200px"
						/>
					);
				})}
			</Table>
		</div>
	);
};

export default TimeSummaryTable;
