import { Table, Tooltip } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { InviteSvg, KeySvg } from 'utils/svgs';
import SearchAndFilter from '../SearchAndFilter';
import styles from './index.module.scss';
import { getApi, postApi } from 'redux/apis';
import moment from 'moment';
import { toastText } from 'utils/utils';
import ChangePasswordModal from '../ChangePasswordModal';
import { useNavigate } from 'react-router-dom';

interface Pagination {
	page: number;
	limit: number;
	search?: string;
	status?: string;
	startDate?: string;
	endDate?: string;
}

interface Admin {
	userId: string;
	fullname: string;
	email: string;
	status: string;
	subscriptions: string;
	companies: string;
	createdAt: string;
	isLoading?: boolean;
}

const SuperAdminTable = () => {
	const { Column } = Table;

	const [isInViewPort, setIsInViewPort] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>('');
	const [applySearch, setApplySearch] = useState<boolean>(false);
	const [filterValue, setFilterValue] = useState<string>('All');
	const [dateValue, setDateValue] = useState<any>(null);
	const [pageNo, setPageNo] = useState(1);
	const [users, setUsers] = useState<Admin[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [inviteLoading, setInviteLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [user, setUser] = useState<string | null>(null);
	const [isPasswordLoading, setIsPasswordLoading] = useState(false);

	const [debounceSearchValue] = useDebounce(searchValue, 1000);

	const navigate = useNavigate();

	const handleReinvite = async (id: string) => {
		try {
			setInviteLoading(true);
			const data = users.map((item) => {
				if (item.userId === id) {
					return {
						...item,
						isLoading: true,
					};
				} else {
					return item;
				}
			});
			setUsers(data);
			await postApi('/auth/resend-welcome-email', {
				userId: id,
			});
			toastText('User reinvited successfully.', 'success');
		} catch (err: any) {
			let errorMessage = 'Error while sending welcome email';

			if (err?.response?.data?.message) {
				errorMessage = err?.response?.data?.message;
			}

			toastText(errorMessage, 'error');

			if (err?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			}
		} finally {
			const data = users.map((item) => {
				if (item.userId === id) {
					return {
						...item,
						isLoading: false,
					};
				} else {
					return item;
				}
			});
			setUsers(data);
			setInviteLoading(false);
		}
	};

	const handleChangePassword = async (id: string) => {
		setUser(id);
		setIsModalOpen(true);
	};

	const submitHandler = async (data: any) => {
		setIsPasswordLoading(true);
		try {
			setIsPasswordLoading(true);
			const response = await postApi('/auth/admin/change-password', {
				userId: user,
				password: data.password,
			});
			toastText(response.data.message, 'success');
		} catch (err: any) {
			let errorMessage = 'Error while changing password';

			if (err?.response?.data?.message) {
				errorMessage = err?.response?.data?.message;
			}

			toastText(errorMessage, 'error');

			if (err?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			}
		} finally {
			setUser(null);
			setIsPasswordLoading(false);
			setIsModalOpen(false);
		}
	};

	const handleDateRange = (dates: any) => {
		setApplySearch(true);
		setDateValue(dates);
	};

	const fetchData = async (params: Pagination, isPagination?: boolean) => {
		try {
			setIsLoading(true);
			const response = await getApi('/users/admins', params);

			if (isPagination) {
				setTotalCount(response.data.data.count);

				const _data = [...users, ...response.data.data.content].map((item) => {
					return { ...item, isLoading: false };
				});

				setUsers(_data);
			} else {
				setTotalCount(response.data.data.count);
				const _data = [...response.data.data.content].map((item) => {
					return { ...item, isLoading: false };
				});
				setUsers(_data);
			}
		} catch (err: any) {
			let errorMessage = 'Error while fetching users';

			if (err?.response?.data?.message) {
				errorMessage = err?.response?.data?.message;
			}

			toastText(errorMessage, 'error');

			if (err?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			}
		} finally {
			setIsLoading(false);
		}
	};

	const getAdminListingData = async (isPagination?: boolean) => {
		if (isPagination) {
			setPageNo((prev: any) => prev + 1);
		} else {
			setIsInViewPort(false);
			setPageNo(1);
		}
		const query: any = {
			page: isPagination ? pageNo + 1 : 1,
			limit: 10,
			search: searchValue,
			status: filterValue,
		};

		if (dateValue && dateValue.length) {
			query['startDate'] = moment(dateValue[0].$d).startOf('day').toISOString();
			query['endDate'] = moment(dateValue[1].$d).endOf('day').toISOString();
		}

		await fetchData(query, isPagination);
	};

	const scrollHandler = useCallback((event: any) => {
		const { currentTarget } = event;
		const tableBody = currentTarget?.querySelector('tbody');
		if (
			tableBody?.getBoundingClientRect().top +
				tableBody.getBoundingClientRect().height <
			screen.height - 100
		) {
			setIsInViewPort(true);
		} else {
			setIsInViewPort(false);
		}
	}, []);

	useEffect(() => {
		const tableBody = document.querySelector('.ant-table-body');
		tableBody?.addEventListener('scroll', scrollHandler);
		return () => {
			// Remove scroll event listener when component unmounts
			tableBody?.removeEventListener('scroll', scrollHandler);
		};
	}, [users]);

	useEffect(() => {
		getAdminListingData();
	}, []);

	useEffect(() => {
		(async () => {
			if (isInViewPort && users.length < totalCount) {
				await getAdminListingData(true);
			}
		})();
	}, [isInViewPort]);

	useEffect(() => {
		if (applySearch) {
			getAdminListingData();
		}
	}, [debounceSearchValue, filterValue, dateValue, applySearch]);

	return (
		<div>
			<div>
				<SearchAndFilter
					searchValue={searchValue}
					filterValue={filterValue}
					dateValue={dateValue}
					performSearchHandler={(value: string) => {
						setSearchValue(value);
						setApplySearch(true);
					}}
					performFilterHandler={(value: string) => {
						setFilterValue(value);
						setApplySearch(true);
					}}
					performDateHandler={handleDateRange}
				/>
			</div>
			<div className={styles['super-admin__table']}>
				<Table
					dataSource={users}
					className={styles['super-admin__table']}
					pagination={false}
					loading={isLoading}
					scroll={{ y: '55vh' }}
					rowKey={(row: Admin) => {
						return row.userId;
					}}
				>
					<Column
						title="Admin Name"
						dataIndex="name"
						key="name"
						className="bg-white"
						width="15%"
					/>
					<Column
						title="Email"
						dataIndex="email"
						key="email"
						className="bg-white"
						width="15%"
					/>
					<Column
						title="Status"
						dataIndex="status"
						key="status"
						className="bg-white"
						width="15%"
						render={(value: string) => (
							<span
								style={{ color: value === 'Active' ? '#009049' : '#ff5f15' }}
							>
								{value}
							</span>
						)}
					/>
					<Column
						title="Subscriptions"
						dataIndex="subscriptions"
						key="subscriptions"
						className="bg-white"
						width="15%"
					/>
					<Column
						title="Connected Companies"
						dataIndex="companies"
						key="companies"
						className="bg-white"
						width="16%"
					/>
					<Column
						title="Registered On"
						dataIndex="createdAt"
						key="createdAt"
						className="bg-white"
						width="16%"
						render={(value: string) => {
							return moment(value).format('MM/DD/YYYY');
						}}
					/>
					<Column
						title="Action"
						dataIndex="action"
						key="action"
						className="bg-white"
						width="8%"
						render={(value, data: Admin) => {
							return (
								<div style={{ display: 'flex' }}>
									<Tooltip
										title="Reinvite Admin"
										placement="topLeft"
									>
										<div
											className={`${styles['super-admin__table-actions']}  ${
												inviteLoading && 'pointer-event-none'
											}`}
											onClick={() => handleReinvite(data.userId)}
										>
											{data.isLoading ? (
												<img
													src="/assets/gifs/loading-black.gif"
													height={38}
												/>
											) : (
												<InviteSvg />
											)}
										</div>
									</Tooltip>
									<Tooltip
										title="Change Password"
										placement="topLeft"
									>
										<div
											className={`${styles['super-admin__table-actions']}  ${
												inviteLoading && 'pointer-event-none'
											}`}
											onClick={() => handleChangePassword(data.userId)}
										>
											<KeySvg />
										</div>
									</Tooltip>
								</div>
							);
						}}
					/>
				</Table>
			</div>
			{isModalOpen && (
				<ChangePasswordModal
					isModalOpen={isModalOpen}
					handleOk={submitHandler}
					handleCancel={() => {
						setIsModalOpen(false);
					}}
					isPasswordLoading={isPasswordLoading}
				/>
			)}
		</div>
	);
};

export default SuperAdminTable;
