import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi } from 'redux/apis';

export const getCustomRulesAction = createAsyncThunk(
	'custom-rule/get',
	async (queryParameters: any = {}, { rejectWithValue }) => {
		try {
			const params = {
				...queryParameters,
				companyId: localStorage.getItem('companyId'),
			};
			const response = await getApi('/custom-rule', params);
			return response.data.data;
		} catch (error: any) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const getSplitCustomRulesAction = createAsyncThunk(
	'custom-rule/split',
	async (queryParameters: any = {}, { rejectWithValue }) => {
		try {
			const params = {
				...queryParameters,
				companyId: localStorage.getItem('companyId'),
			};
			const response = await getApi('/custom-rule/split', params);
			return response.data.data;
		} catch (error: any) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const getEditCustomRulesAction = createAsyncThunk(
	'custom-rule/edit',
	async (queryParameters: any = {}, { rejectWithValue }) => {
		try {
			const params = {
				...queryParameters,
				companyId: localStorage.getItem('companyId'),
			};
			const response = await getApi('/custom-rule/edit', params);
			return response.data.data;
		} catch (error: any) {
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const getDeleteCustomRulesAction = createAsyncThunk(
	'custom-rule/delete',
	async (queryParameters: any = {}, { rejectWithValue }) => {
		try {
			const params = {
				...queryParameters,
				companyId: localStorage.getItem('companyId'),
			};
			const response = await getApi('/custom-rule/delete', params);
			return response.data.data;
		} catch (error: any) {
			return rejectWithValue(error?.response?.data);
		}
	}
);
