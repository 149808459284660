import { monthNames } from 'constants/Data';
import moment from 'moment';
import toast from 'react-hot-toast';
import { getApi, postApi } from 'redux/apis';
import { WarningOutlined } from '@ant-design/icons'; // Import the Ant Design icon for info

export const toastText = (message: string, type: string) => {
	switch (type) {
		case 'success':
			toast.success(message, {
				style: {
					fontSize: '16px',
				},
			});
			break;

		case 'error':
			toast.error(message, {
				style: {
					fontSize: '16px',
				},
			});
			break;

		case 'info':
			toast.custom(() => (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						color: 'black', // White text color for better visibility
						backgroundColor: '#fff', // Semi-transparent background
						padding: '10px 15px',
						borderRadius: '8px',
						fontSize: '16px',
						backdropFilter: 'blur(10px)', // Blur effect
						WebkitBackdropFilter: 'blur(10px)', // Safari support for blur
					}}
				>
					{/* Ant Design InfoCircleOutlined Icon for info type */}
					<WarningOutlined
						style={{ marginRight: '8px', fontSize: '20px', color: '#f7c600' }}
					/>
					<span>{message}</span>
				</div>
			));
			break;

		default:
			console.warn(`Unknown toast type: ${type}`);
	}
};

export const formatPhoneNumber = (phoneNumber: string) => {
	// Remove all non-numeric characters from the input
	const cleanedNumber = phoneNumber.replace(/\D/g, '');

	// Define the phone number format (e.g., "(XXX) XXX-XXXX")
	const format = '($1) $2-$3';

	// Apply the mask to the cleaned number using a regular expression
	const maskedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, format);

	return maskedNumber;
};

export const getMonthAfter12Months = (currentMonth: number) => {
	// Parse the input month to a Date object
	const date = new Date(`2023-${currentMonth}-01`); // We assume the year is 2023 for simplicity

	// Add 12 months to the current date
	date.setMonth(date.getMonth() + 11);

	// Get the new month (0-11, where 0 is January and 11 is December)
	const newMonth = date.getMonth();

	// Return the new month as a number (add 1 to get the month number in the range 1-12)

	return monthNames[newMonth];
};

export const getPermissionObject = (
	permissionObj: any,
	allPermissions: any
) => {
	let formattedArray: any = [];
	const updatedAllPermission = allPermissions.map((singlePermission: any) => {
		return {
			...singlePermission,
			isBold: false,
		};
	});

	// const updatedPermissionObj = []
	for (const singlePermissionObj of permissionObj) {
		let tempArray = [];
		if (singlePermissionObj.name) {
			tempArray.push({
				permissionName: singlePermissionObj.name,
				isBold: true,
			});
		}

		const filteredPermissions = updatedAllPermission.filter(
			(singlePermission: any) => {
				return singlePermissionObj.items.includes(singlePermission.sortId);
			}
		);
		tempArray = [...tempArray, ...filteredPermissions];
		formattedArray = [...formattedArray, ...tempArray];
	}

	return formattedArray;
};

export const checkPermission = (
	allPermissions: any,
	requiredPermission: any
) => {
	if (!allPermissions) {
		return false;
	}
	const permissionsList = allPermissions;
	const permission = permissionsList?.find(
		(singlePermission: any) =>
			singlePermission.permissionName === requiredPermission.permissionName
	);

	if (permission) {
		const permitted = requiredPermission.permission.some(
			(singlePermission: string) => permission[singlePermission]
		);
		return permitted || permission['all'];
	} else {
		return false;
	}
};

export const isValidConfigurationSettings = (
	configurationsDataCopy: any,
	indirectExpenseRate: number | null
) => {
	let fields: any = [];
	let remainingId = null;
	for (const configuration of Object.values(configurationsDataCopy) as any) {
		fields = [...fields, ...Object.values(configuration.fields)];
	}

	for (const configuration of Object.values(configurationsDataCopy) as any) {
		const fondedConfiguration: any = Object.values(configuration.fields).find(
			(el: any) => el.isEditing === true
		);
		if (fondedConfiguration) {
			remainingId = `${configuration.id}${fondedConfiguration.id}`;
			break;
		}
	}

	//const isValid = fields.every((singleField: any) => singleField.value);

	//NOTE: if somehow in future this validation not work comment down the below code and un comment the above code.

	let _isValid = true;
	let _isValidCreditAccount = true;

	let shouldBreak = false;

	for (const configuration of Object.values(configurationsDataCopy) as any) {
		const sectionFields: any[] = Object.values(configuration.fields);

		if (shouldBreak) {
			break;
		}

		for (const field of sectionFields) {
			if (
				configuration.id !== '5' &&
				!(configuration.id == '0' && field.id === 'f1') &&
				!(configuration.id == '4' && !indirectExpenseRate) &&
				!(
					configuration.id == '0' &&
					field.id === 'f2' &&
					!indirectExpenseRate
				) &&
				field.isActive
			) {
				_isValid = hasText(field.value);
			}
			if (
				(configuration.id === '1' ||
					configuration.id === '2' ||
					configuration.id === '3') &&
				field.isActive
			) {
				_isValidCreditAccount = hasText(field.creditValue);
			}

			if (!_isValid) {
				shouldBreak = true;
				break;
			}
			if (!_isValidCreditAccount) {
				shouldBreak = true;
				break;
			}
		}
	}

	const isAllEditableDone = fields.every(
		(singleField: any) => singleField.isEditing === false
	);

	const isBlankLabel = fields.some(
		(singleField: any) => singleField.label.trim() === ''
	);
	return {
		isValidSelectBox: _isValid && _isValidCreditAccount,
		isAddEditFalse: isAllEditableDone,
		isBlankLabel: isBlankLabel,
		remainingId: remainingId,
	};
};

export const filterQBEntities = (
	configurationsDataCopy: any,
	type: string,
	qbValues: any
) => {
	const qbValuesCopy = JSON.parse(JSON.stringify(qbValues));
	let fields: any = [];
	const filteredItems: any = [];

	for (const configuration of Object.values(configurationsDataCopy) as any) {
		if (configuration.type === type) {
			fields = [...fields, ...Object.values(configuration.fields)];
		}
	}

	const fieldsSelectedValues = fields.map(
		(singleField: any) => singleField.value
	);

	const creditFieldsSelectedValues = fields.map(
		(singleField: any) => singleField.creditValue
	);

	qbValuesCopy.forEach((single: any) => {
		if (
			fieldsSelectedValues.includes(single.Id) ||
			creditFieldsSelectedValues.includes(single.Id)
		) {
			single.isDisable = true;
			filteredItems.push(single);
		} else {
			filteredItems.push(single);
		}
	});
	return filteredItems;
};

export const phoneNumberFormatHandler = (phoneNumber: any = '') => {
	const inputPhoneNumber = phoneNumber?.replace(/\D/g, ''); // Remove non-digit characters
	let formattedPhoneNumber = '';

	if (inputPhoneNumber.length > 0) {
		formattedPhoneNumber = `(${inputPhoneNumber.slice(0, 3)}`;

		if (inputPhoneNumber.length >= 4) {
			formattedPhoneNumber += `) ${inputPhoneNumber.slice(3, 6)}`;
		}

		if (inputPhoneNumber.length >= 7) {
			formattedPhoneNumber += `-${inputPhoneNumber.slice(6, 10)}`;
		}
	}

	return formattedPhoneNumber;
};

export const TimeAgo = (date: any) => {
	// const sourceTimeZone = 'America/Los_Angeles';
	const localDate = moment(date).utcOffset(new Date().getTimezoneOffset() * -1);
	const formattedTimeAgo = localDate.fromNow();

	return formattedTimeAgo;
	// return <span>{formattedTimeAgo}</span>;
};

export const checkTimeFormat = (value: string) => {
	let result = true;
	for (let i = 0; i < value.length; i++) {
		if (
			!(value.charCodeAt(i) >= 48 && value.charCodeAt(i) <= 58) ||
			value === ''
		) {
			result = false;
			break;
		}
	}
	return result;
};

export function convertToHyphenCase(inputString: string) {
	// Convert the input string to lowercase
	const lowerCaseString = inputString.toLowerCase();

	// Replace spaces with hyphens
	const hyphenCaseString = lowerCaseString.replace(/\s+/g, '-');

	return hyphenCaseString;
}

export const createPDF = async (date: any, companyName: string) => {
	const response = await getApi('/employee-cost', {
		companyId: localStorage.getItem('companyId'),
		date: date,
		page: 1,
		limit: 100000,
	});
	const dateCopy = new Date(date);
	const formattedDate = dateCopy.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'long',
	});
	let top: any = `<div style="margin: 0; padding: 0; box-sizing: border-box; font-family: Arial, Helvetica, sans-serif;">
			<div style="margin-bottom:20px;  ">
							<img src='https://costallocationspro.s3.amazonaws.com/cap-logonew.png' width="180px" style="float: left;"/>
							<p style="text-align: center; padding-top: 12px; padding-right:150px">Employee Cost</p>
			</div>
			 <div>
        <p style="display: inline-block;">Date : ${formattedDate}</p>
        <p style="display: inline-block; float: right;">Company : ${companyName}</p>
      </div>
      <table
        style="border-collapse: collapse;  width: 100%;"
      >
        <thead style="color: #fff; background: #333">
          <tr>
            <td>Employee Name</td>
            <td>Employee Type</td>
            <td>Maximum allocate hours per year</td>
            <td>Maximum Vacation/PTO hours per year</td>
            <td>Total Salary</td>
            <td>Total Fringe</td>
            <td>Total Payroll Taxes</td>
            <td>Total Labor Burden</td>
          </tr>
        </thead>
        <tbody>`;
	response.data.data.employees.forEach((single: any) => {
		const employeeType = single.employeeCostField.find(
			(single: any) => single.field.name === 'Employee Type'
		)?.costValue[0]?.value;
		const maximumAllocatedVacationHoursPerYear = single.employeeCostField.find(
			(single: any) =>
				single.field.name === 'Maximum Vacation/PTO hours per year'
		)?.costValue[0]?.value;
		const maximumAllocatedHoursPerYear = single.employeeCostField.find(
			(single: any) => single.field.name === 'Maximum allocate hours per year'
		)?.costValue[0]?.value;
		const totalSalary = single.employeeCostField.find(
			(single: any) => single.field.name === 'Total Salary'
		)?.costValue[0]?.value;
		const totalFringe = single.employeeCostField.find(
			(single: any) =>
				single.field.name === 'Total Payroll Taxes & Fringe Benefits'
		)?.costValue[0]?.value;
		const TotalPayrollTaxes = single.employeeCostField.find(
			(single: any) => single.field.name === 'Total Other Expenses'
		)?.costValue[0]?.value;

		top += `<tr
            style="
              background-color: #ddd;
              font-weight: 700;
              border-bottom: 1px solid #fff;
            "
          >
            <td>${single.fullName}</td>
            <td>${employeeType ? convertToTitleCase(employeeType) : 'N/A'}</td>
            <td>${maximumAllocatedHoursPerYear}</td>
            <td>${maximumAllocatedVacationHoursPerYear}</td>
            <td>$${formatNumberWithCommas(totalSalary)}</td>
            <td>$${formatNumberWithCommas(totalFringe)}</td>
            <td>$${formatNumberWithCommas(TotalPayrollTaxes)}</td>
            <td>$${formatNumberWithCommas(
							(
								Number(totalFringe) +
								Number(totalSalary) +
								Number(TotalPayrollTaxes)
							).toFixed(2)
						)}</td>
          </tr>`;
	});

	const footer = `</tbody>
      </table>
    </div>`;
	return top + footer;
};
export const downLoadPdfHours = async (date: any, companyName: string) => {
	const data = await createPDF(date, companyName);
	const response = await postApi('/time-activities/exportpdf', {
		html: [btoa(data!)],
		filename: `Employee Cost-${moment(new Date()).format('MMDDYYYYhhmmss')}`,
	});

	const pdf = {
		file: response?.data?.data,
		fileName: `Employee Cost-${moment(new Date()).format('MMDDYYYYhhmmss')}`,
	};
	// const pdfLink = `${pdf.file}`;
	const anchorElement = document.createElement('a');
	const fileName = `${pdf.fileName}.pdf`;
	anchorElement.href = 'data:application/pdf;base64,' + pdf?.file;
	anchorElement.download = fileName;
	anchorElement.click();
};

export const createPDFPercentage = async (
	payPeriodId: string,
	companyName: string,
	range: string,
	includeInactive: boolean,
	configurationSections: any
) => {
	const response = await getApi('/employee-cost', {
		companyId: localStorage.getItem('companyId'),
		payPeriodId,
		page: 1,
		limit: 100000,
		isPdf: true,
		includeInactive,
	});

	let top: any = `<div style="margin: 0; padding: 0; box-sizing: border-box; font-family: Arial">
			<div style="margin-bottom:20px;  ">
							<img src='https://costallocationspro.s3.amazonaws.com/cap-logonew.png' width="180px" style="float: left;"/>
							<p style="text-align: center; padding-top: 12px; padding-right:150px">Employee Cost</p>
			</div>
			 <div>
        <p style="display: inline-block;">Date : ${range}</p>
        <p style="display: inline-block; float: right;">Company : ${companyName}</p>
      </div>
      <table
        style="border-collapse: collapse;  width: 100%;"
      >
          <tr>
            <th style="background-color: #333; color: white; padding: 8px; text-align: left;">Employee Name</th>
            <th style="background-color: #333; color: white; padding: 8px 0px 8px 8px; text-align: right;">Total Salary</th>
            <th style="background-color: #333; color: white; padding: 8px 0px 8px 8px; text-align: right;">Total Payroll Taxes & Fringe Benefits</th>
           ${
							configurationSections &&
							configurationSections.length &&
							configurationSections[3]?.fields[
								configurationSections[3]?.fields.length - 1
							]?.isActive
								? `<th style="background-color: #333; color: white; padding: 8px 0px 8px 8px; text-align: right;"
								>
									Total Other Expenses
								</th>`
								: ''
						}
            <th style="background-color: #333; color: white; padding: 8px 20px 8px 8px; text-align: right;">Total Labor Burden</th>
          </tr>
         <tbody>`;

	let totalEmployeeSalary = 0;
	let totalFringeSalary = 0;
	let totalPayrollTaxes = 0;

	response?.data?.data?.employees.forEach((single: any) => {
		const totalSalary = single.employeeCostField.find(
			(single: any) => single.field.name === 'Total Salary'
		)?.costValue[0]?.value;
		totalEmployeeSalary += Number(totalSalary);
		const totalFringe = single.employeeCostField.find(
			(single: any) =>
				single.field.name === 'Total Payroll Taxes & Fringe Benefits'
		)?.costValue[0]?.value;
		totalFringeSalary += Number(totalFringe);

		const totalOtherExpenses = single.employeeCostField.find(
			(single: any) => single.field.name === 'Total Other Expenses'
		);
		const TotalPayrollTaxes =
			totalOtherExpenses && totalOtherExpenses?.costValue[0]?.value;
		totalPayrollTaxes += Number(TotalPayrollTaxes);
		top += `<tr
            style="
              background-color: #ddd;
              font-weight: 700;
              border-bottom: 1px solid #fff;
            "
          >
            <td style="padding-bottom:12px;padding-top:12px;padding-left:12px;">${
							single.fullName
						}</td>
            <td style="text-align: right;">$${formatNumberWithCommasV2(
							totalSalary
						)}</td>
            <td style="text-align: right;">$${formatNumberWithCommasV2(
							totalFringe
						)}</td>
						${
							configurationSections &&
							configurationSections.length &&
							configurationSections[3]?.fields[
								configurationSections[3]?.fields.length - 1
							]?.isActive
								? `<td style="text-align:right">
									$${TotalPayrollTaxes ? formatNumberWithCommasV2(TotalPayrollTaxes) : 0}
								</td>`
								: ''
						}

            <td style="text-align: right;padding-right:20px;">$${formatNumberWithCommasV2(
							Number(totalFringe) +
								Number(totalSalary) +
								(TotalPayrollTaxes ? Number(TotalPayrollTaxes) : 0)
						)}</td>
          </tr>`;
	});
	const bottom = `<tr
            style="
              background-color: #ddd;
              font-weight: 700;
              border-bottom: 1px solid #fff;
            "
          >
            <td style="padding-bottom:12px;padding-top:12px;padding-left:12px;">Total</td>
            <td style="text-align: right;">$${formatNumberWithCommasV2(
							totalEmployeeSalary
						)}</td>
            <td style="text-align: right;">$${formatNumberWithCommasV2(
							totalFringeSalary
						)}</td>
						${
							configurationSections &&
							configurationSections.length &&
							configurationSections[3]?.fields[
								configurationSections[3]?.fields.length - 1
							]?.isActive
								? `<td style="text-align:right">
									$${formatNumberWithCommasV2(totalPayrollTaxes)}
								</td>`
								: ''
						}

            <td style="text-align: right; padding-right:20px;">$${formatNumberWithCommasV2(
							Number(totalEmployeeSalary) +
								Number(totalFringeSalary) +
								(totalPayrollTaxes ? Number(totalPayrollTaxes) : 0)
						)}</td>
          </tr>`;
	const footer = `</tbody>
      </table>
    </div>`;
	return top + bottom + footer;
};
export const downLoadPdfPercentage = async (
	payPeriodId: string,
	companyName: string,
	range: string,
	includeInactive: boolean,
	configurationSections: any
) => {
	const data = await createPDFPercentage(
		payPeriodId,
		companyName,
		range,
		includeInactive,
		configurationSections
	);
	const response = await postApi('/time-activities/exportpdf', {
		html: [btoa(data!)],
		filename: `Employee Cost-${moment(new Date()).format('MMDDYYYYhhmmss')}`,
	});

	const pdf = {
		file: response?.data?.data,
		fileName: `Employee Cost-${moment(new Date()).format('MMDDYYYYhhmmss')}`,
	};
	// const pdfLink = `${pdf.file}`;
	const anchorElement = document.createElement('a');
	const fileName = `${pdf.fileName}.pdf`;
	anchorElement.href = 'data:application/pdf;base64,' + pdf?.file;
	anchorElement.download = fileName;
	anchorElement.click();
};

export const createPDFTimeActivities = async (
	query: any,
	companyName: string
) => {
	const latestData: any = await getApi('/time-activities', {
		companyId: localStorage.getItem('companyId'),
		page: 1,
		limit: 100000,
		...query,
	});

	const formatedStartDate = moment(query?.payPeriodData?.startDate).format(
		'MM/DD/YYYY'
	);
	const formatedEndDate = moment(query?.payPeriodData?.endDate).format(
		'MM/DD/YYYY'
	);

	const top = `
						<div style="margin: 0; padding: 0; box-sizing: border-box; font-family: Arial">
						<div style="margin-bottom:14px;  ">
							<img src='https://costallocationspro.s3.amazonaws.com/cap-logonew.png' width="180px" style="float: left;"/>
							<p style="text-align: center; padding-top: 12px; padding-right:150px;">Time Activity</p>
						</div>
						<div>
							<p style="display: inline-block;">Date : ${
								formatedStartDate === formatedEndDate
									? `Till ${formatedEndDate}`
									: `${formatedStartDate} to ${formatedEndDate}`
							} </p>
							<p style="display: inline-block; float: right;">Company : ${companyName}</p>
						</div>
							<div>
							<table style="border-collapse: separate; width: 100%; border-spacing: 0px 2px;">

								<tr>
								<th style="background-color: #333; color: white; padding: 14px 8px; text-align: left; font-size: 14px;">Activity Date</th>
								<th style="background-color: #333; color: white; padding: 14x 8px; text-align: left; font-size: 14px;">Employee</th>
								<th style="background-color: #333; color: white; padding: 14px 8px; text-align: left; font-size: 14px;">Customer</th>
								<th style="background-color: #333; color: white; padding: 14px 8px; text-align: left; font-size: 14px;">Class</th>
								<th style="background-color: #333; color: white; padding: 14px 8px; text-align: left; font-size: 14px;">Hrs</th>
								</tr>
					`;

	let body = '';

	latestData?.data?.data?.timeActivities?.forEach((singleActivity: any) => {
		const date = moment(singleActivity?.activityDate).format('MM-DD-YYYY');
		if (
			singleActivity?.SplitTimeActivities &&
			singleActivity?.SplitTimeActivities.length > 0
		) {
			singleActivity?.SplitTimeActivities.forEach(
				(singleSplitActivity: any) => {
					body += `<tr>
											<td style="background-color: #ddd; padding: 14px 8px; text-align: left;">
											 	${date}
											</td>
											<td style="background-color: #ddd; padding: 14px 8px; text-align: left;">
												 	${singleActivity?.employee ? singleActivity?.employee?.fullName : 'NA'}
											</td>
											<td style="background-color: #ddd; padding: 14px 8px; text-align: left;">
												${singleSplitActivity?.customerName ? singleSplitActivity?.customerName : 'NA'}
											</td>
											<td style="background-color: #ddd; padding: 14px 8px; text-align: left;">
												${singleSplitActivity?.className ? singleSplitActivity?.className : 'NA'}
											</td>
											<td style="background-color: #ddd; padding: 14px 8px; text-align: left;">
												${singleSplitActivity?.hours?.padStart(
													2,
													'0'
												)}:${singleSplitActivity?.minute?.padStart(2, '0')}
											</td>
										</tr>`;
				}
			);
		} else {
			body += `<tr>
											<td style="background-color: #ddd; padding: 14px 8px; text-align: left;">
												${date}
											</td>
											<td style="background-color: #ddd; padding: 14px 8px; text-align: left;">
												${singleActivity?.employee ? singleActivity?.employee?.fullName : 'NA'}
											</td>
											<td style="background-color: #ddd; padding: 14px 8px; text-align: left;">
												${singleActivity?.customerName ? singleActivity?.customerName : 'NA'}
											</td>
											<td style="background-color: #ddd; padding: 14px 8px; text-align: left;">
												${singleActivity?.className ? singleActivity?.className : 'NA'}
											</td>
											<td style="background-color: #ddd; padding: 14px 8px; text-align: left;">
												${singleActivity?.hours?.padStart(2, '0')}:${singleActivity?.minute?.padStart(
				2,
				'0'
			)}
											</td>
										</tr>`;
		}
	});

	const footer = `</tbody>
						</table>
					</div>`;

	const htmlData = top + body + footer;
	return htmlData;
};

export const downloadPDFTimeActivities = async (
	query: any,
	companyName: string
) => {
	const htmlData = await createPDFTimeActivities(query, companyName);

	const dataArr = [btoa(htmlData!)];

	// const formatedStartDate = moment(query?.startDate).format('MM/DD/YYYY');
	// const formatedEndDate = moment(query?.endDate).format('MM/DD/YYYY');

	const response = await postApi('/time-activities/exportpdf', {
		html: dataArr,
		filename: `Time Log Report_${moment(new Date()).format('MMDDYYYYhhmmss')} `,
	});

	const pdf = {
		file: response?.data?.data,
		fileName: `Time Log Report_${moment(new Date()).format('MMDDYYYYhhmmss')} `,
	};
	// const pdfLink = `${pdf.file}`;
	const anchorElement = document.createElement('a');
	const fileName = `${pdf.fileName}.pdf`;
	anchorElement.href = 'data:application/pdf;base64,' + pdf?.file;
	anchorElement.download = fileName;
	anchorElement.click();
};

export function convertToTitleCase(inputString: any) {
	// Split the input string by underscores
	const words = inputString.split('_');

	// Capitalize the first letter of each word
	const capitalizedWords = words.map((word: any) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	});

	// Join the words with a space
	const result = capitalizedWords.join(' ');

	return result;
}

export function formatNumberWithCommas(number: any) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatNumberWithCommasV2(number: any) {
	return Number(number)
		.toFixed(2)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function divideTimeInHalf(hours: any, minutes: any) {
	// Convert hours and minutes to total minutes
	const totalMinutes = hours * 60 + minutes;

	// Calculate half of the total minutes
	const halfTotalMinutes = Math.floor(totalMinutes / 2);

	// Calculate the new hours and minutes
	const halfHours = Math.floor(halfTotalMinutes / 60);
	const halfMinutes = halfTotalMinutes % 60;

	// Return an object with half hours and half minutes

	return {
		halfHours,
		halfMinutes,
	};
}

export function addTimeToTime(
	hoursToAdd: number,
	minutesToAdd: number,
	currentHours: number,
	currentMinutes: number
) {
	// Convert current time to total minutes
	const totalMinutes = currentHours * 60 + currentMinutes;

	// Add the hours and minutes to the total minutes
	const newTotalMinutes = totalMinutes + hoursToAdd * 60 + minutesToAdd;

	// Calculate the new hours and minutes
	const newHours = Math.floor(newTotalMinutes / 60);
	const newMinutes = newTotalMinutes % 60;

	// Return an object with the new hours and minutes
	return {
		newHours,
		newMinutes,
	};
}

export function isDateRangeDisabled(
	startDate: any,
	endDate: any,
	disabledDates: any
) {
	// Convert the startDate and endDate strings to Date objects
	startDate = new Date(startDate);
	endDate = new Date(endDate);

	// Loop through the range of dates from startDate to endDate
	for (
		let currentDate = startDate;
		currentDate <= endDate;
		currentDate.setDate(currentDate.getDate() + 1)
	) {
		// Convert the currentDate to a string in the format 'YYYY-MM-DD'
		const currentDateStr = moment(currentDate).format('YYYY-MM-DD');

		// Check if the currentDateStr is in the list of disabledDates
		if (disabledDates.includes(currentDateStr)) {
			return true; // Date in range is disabled
		}
	}

	return false; // No disabled dates found in the range
}

export function isNullOrUndefined(value: any) {
	return null === value || undefined === value;
}

export function isBlank(value: any) {
	return (
		null === value ||
		undefined === value ||
		value.toString().trim().length === 0
	);
}

export function hasText(value: any) {
	return !isBlank(value);
}
export function divideTimeBySlots(
	hours: number,
	minutes: number,
	divisor: number
) {
	if (divisor <= 0) {
		return 'Divisor must be greater than zero';
	}

	const totalTimeInMinutes = hours * 60 + minutes;
	const dividedTimeInMinutes = totalTimeInMinutes / divisor;

	const dividedHours = Math.floor(dividedTimeInMinutes / 60);
	const dividedMinutes = Math.floor(dividedTimeInMinutes % 60);

	return { dividedHours, dividedMinutes };
}

export function makeTotalTime(timeArray: any) {
	let totalHours = 0;
	let totalMinutes = 0;

	// Loop through the array of time objects and add up the hours and minutes
	for (const timeObj of timeArray) {
		if (
			timeObj &&
			typeof timeObj.minutes === 'number' &&
			typeof timeObj.hours === 'number'
		) {
			totalHours += timeObj.hours;
			totalMinutes += timeObj.minutes;
		}
	}

	// Calculate carryover hours from extra minutes
	totalHours += Math.floor(totalMinutes / 60);
	totalMinutes %= 60;
	return { hours: totalHours, minutes: totalMinutes };
}

export function timeDifference(time1: any, time2: any) {
	// Extract hours and minutes from the time objects
	const { hours: hours1, minutes: minutes1 } = time1;
	const { hours: hours2, minutes: minutes2 } = time2;

	// Calculate the total minutes for each time
	const totalMinutes1 = hours1 * 60 + minutes1;
	const totalMinutes2 = hours2 * 60 + minutes2;

	// Calculate the absolute difference in minutes
	const minuteDifference = Math.abs(totalMinutes1 - totalMinutes2);

	// Calculate hours and remaining minutes
	const hoursDifference = Math.floor(minuteDifference / 60);
	const remainingMinutes = minuteDifference % 60;

	return { hours: hoursDifference, minutes: remainingMinutes };
}

export function generateDateRange(startDateStr: any, endDateStr: any) {
	const dateList = [];
	const startDate = new Date(startDateStr);
	const endDate = new Date(endDateStr);

	while (startDate <= endDate) {
		const year = startDate.getFullYear();
		const month = String(startDate.getMonth() + 1).padStart(2, '0');
		const date = String(startDate.getDate()).padStart(2, '0');
		dateList.push(`${year}-${month}-${date}`);
		startDate.setDate(startDate.getDate() + 1);
	}

	return dateList;
}

export function getLastDateOfMonth(payPeriodEndDate: string | Date) {
	const date = new Date(payPeriodEndDate);
	date.setMonth(date.getMonth() + 1, 1);
	date.setDate(date.getDate() - 1);
	return date;
}

export function base64ToBlob(base64: string, mime: string) {
	const byteCharacters = atob(base64);
	const arrayBuffer = new ArrayBuffer(byteCharacters.length);
	const uint8Array = new Uint8Array(arrayBuffer);
	for (let i = 0; i < byteCharacters.length; i++) {
		uint8Array[i] = byteCharacters.charCodeAt(i);
	}
	return new Blob([uint8Array], { type: mime });
}

export const getCustomCustomerData = (testData: any) => {
	const parentIds: any = [];
	const childrenIds: any = [];
	testData.forEach((singleData: any) => {
		if (singleData.ParentRef) {
			childrenIds.push(singleData.ParentRef.value);
		} else {
			parentIds.push(singleData);
		}
	});

	function organizeData(data: any, parentId: any) {
		const result = [];
		for (const item of data) {
			if (item.ParentRef && item.ParentRef.value == parentId) {
				const children = organizeData(data, item.Id);
				if (children.length > 0) {
					item.children = children;
				}
				result.push(item);
			}
		}
		return result;
	}

	const organizedData: any = [];

	parentIds.forEach((singleParent: any) => {
		const data = organizeData(testData, singleParent.Id);
		organizedData.push({ ...singleParent, children: data });
	});
};

export function navigateWithPermissions(selectedCompanyPermission: any) {
	const isDashboardPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Dashboard',
		permission: ['view'],
	});
	if (isDashboardPermission) {
		return '/';
	}
	const isEmployeeCostPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Employee Cost',
		permission: ['view'],
	});
	if (isEmployeeCostPermission) {
		return '/employee-costs';
	}
	const isTimeActivityPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Logs',
		permission: ['view'],
	});
	if (isTimeActivityPermission) {
		return '/time-activity';
	}
	const isCostAllocationPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Cost Allocations',
			permission: ['view'],
		}
	);
	if (isCostAllocationPermission) {
		return '/cost-allocations';
	}
	const isJournalEntriesPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Journal Entries',
			permission: ['view'],
		}
	);
	if (isJournalEntriesPermission) {
		return '/journal-entries';
	}
	return '/';
}

export function divideTimeAsPerPercentage(
	hours: any,
	minutes: any,
	actions: any[]
) {
	const minutesContribute: any = {};

	// Convert hours and minutes to total minutes
	const totalMinutes = hours * 60 + minutes;
	let totalMinutesContributed = 0;

	for (let i = 0; i < actions.length; i++) {
		const action = actions[i];

		minutesContribute[i] = Math.round(
			(totalMinutes * Number(action.hours)) / 100
		);

		totalMinutesContributed = totalMinutesContributed + minutesContribute[i];
	}

	const diff = totalMinutes - totalMinutesContributed;

	if (diff && diff > 0) {
		minutesContribute[actions.length - 1] =
			minutesContribute[actions.length - 1] + diff;
	} else if (diff && diff < 0) {
		minutesContribute[actions.length - 1] =
			minutesContribute[actions.length - 1] - Math.abs(diff);
	}

	return minutesContribute;
}

export function minToHours(totalMinutes: number) {
	const totalHours = Math.floor(totalMinutes / 60);
	const remainingMinutes = totalMinutes % 60;

	return `${totalHours.toString().padStart(2, '0')}:${remainingMinutes
		.toString()
		.padStart(2, '0')}`;
}

export function getLocalTime(date: Date) {
	const testDateUtc = moment.utc(date);
	const localDate = moment(testDateUtc).local();

	return localDate.format('MM/DD/YYYY');
}

function deepCopy(obj: any) {
	return JSON.parse(JSON.stringify(obj));
}

export function filterSelectedClass(node: any, selectedId: any) {
	node = deepCopy(node);
	if (node.Id === selectedId) {
		node.disabled = true;
	}

	if (node.children && node.children.length > 0) {
		node.children.map((child: any) => filterSelectedClass(child, selectedId));
	}

	return node;
}

// Function to recursively disable nodes with specific id
function disableNodes(node: any, targetId: any) {
	// Deep copy the node to avoid modifying the original object
	const newNode = deepCopy(node);

	// Modify the 'disabled' property if the id matches
	if (newNode.Id === targetId) {
		newNode.disabled = true;
	}

	// Recursively call the function for children and update the 'children' property
	if (newNode.children && newNode.children.length > 0) {
		newNode.children = newNode.children.map((child: any) =>
			disableNodes(child, targetId)
		);
	}

	return newNode;
}

// Function to iterate through the original array and create a new array with modified nodes
export function createModifiedArray(originalArray: any, targetId: any) {
	const modifiedArray = [];

	for (let i = 0; i < originalArray.length; i++) {
		const modifiedNode = disableNodes(originalArray[i], targetId);
		modifiedArray.push(modifiedNode);
	}

	return modifiedArray;
}

export function createModifiedArrayIds(originalArray: any, targetId: any) {
	const modifiedArray = [];

	for (let i = 0; i < originalArray.length; i++) {
		for (let j = 0; j < targetId.length; j++) {
			const modifiedNode = disableNodes(originalArray[i], targetId[j]);
			modifiedArray.push(modifiedNode);
		}
	}

	return modifiedArray;
}

export const generateYearRange = (
	startMonth: string,
	endMonth: string,
	baseYear: number,
	numberOfPeriods: number
) => {
	const startDate = new Date(`${startMonth} 01, ${baseYear - numberOfPeriods}`);
	const endDate = new Date(`${endMonth} 01, ${baseYear}`);
	const periodList = [];
	const currentDate = startDate;
	while (currentDate <= endDate) {
		const startFormatted = currentDate.toLocaleDateString('en-US', {
			month: 'long',
			year: 'numeric',
		});
		const endDateMinusOneMonth = new Date(currentDate);
		endDateMinusOneMonth.setMonth(endDateMinusOneMonth.getMonth() + 11);
		const endFormatted = endDateMinusOneMonth.toLocaleDateString('en-US', {
			month: 'long',
			year: 'numeric',
		});

		const periodString = `${startFormatted} - ${endFormatted}`;
		periodList.push(periodString);

		currentDate.setFullYear(currentDate.getFullYear() + 1);
	}

	return periodList;
};

export function invalidText(value: string | number | null | undefined) {
	return (
		value == null || value == undefined || value.toString().trim().length == 0
	);
}
