import Table from '../Table';
import styles from './index.module.scss';

const JournalEntriesMain = () => {
	return (
		<div className={styles['journal-entries-container']}>
			<Table />
		</div>
	);
};

export default JournalEntriesMain;
