import { createSlice } from '@reduxjs/toolkit';
import {
	customerAccountAction,
	customerOptionsAction,
	refreshAccountAction,
} from 'redux/action/customerAction';

const initialState = {
	isLoading: false,
	data: [],
	error: null,
	optionData:[],
	isOptionLoading:false,
	optionError:null
};

const customerSlice = createSlice({
	initialState,
	name: 'customer',
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(customerAccountAction.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(customerAccountAction.fulfilled, (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		});
		builder.addCase(customerAccountAction.rejected, (state, action: any) => {
			state.error = action.error;
			state.isLoading = false;
		});
		builder.addCase(customerOptionsAction.pending, (state) => {
			state.isOptionLoading = true;
		});
		builder.addCase(customerOptionsAction.fulfilled, (state, action) => {
			state.optionData = action.payload;
			state.isOptionLoading = false;
		});
		builder.addCase(customerOptionsAction.rejected, (state, action: any) => {
			state.optionError = action.error;
			state.isOptionLoading = false;
		});
		builder.addCase(refreshAccountAction.fulfilled, (state, action) => {
			state.data = action.payload;
		});
	},
});

export default customerSlice;
