/* eslint-disable react-hooks/rules-of-hooks */
import { Space, Switch, Table } from 'antd';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editUserAction } from 'redux/action/userAction';
import { postApi } from 'redux/apis';
import { AppDispatch } from 'redux/store';
import { Tooltip } from 'antd';
import {
	DeleteActionSvg,
	EditActionSvg,
	InviteSvg,
	SortSvgBottom,
	SortSvgTop,
} from 'utils/svgs';
import { checkPermission, toastText } from 'utils/utils';
import SearchAndFilter from '../SearchAndFilter/index';
import './index.scss';
import { DynamicTableProps } from './types';

const DynamicTable: FC<DynamicTableProps> = (props) => {
	// Inits
	const { Column } = Table;
	const {
		userDataSource,
		performSearchHandler,
		performFilterHandler,
		searchValue,
		filterValue,
		showModal,
		openDrawerHandler,
		setDrawerInfoHandler,
		setEditSelectedUser,
		performSortHandler,
	} = props;

	const dispatch = useDispatch<AppDispatch>();

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const editDataHandler = (userObject: any) => {
		openDrawerHandler();
		setDrawerInfoHandler('Edit User');
		setEditSelectedUser(userObject);
	};

	const deleteDataHandler = (userObject: any) => {
		setEditSelectedUser(userObject);
	};

	const statusHandler = (value: any, data: any) => {
		const finalData = {
			roleId: data?.roleId,
			userId: data?.userId,
			status: value,
			isChangeStatus: true,
		};
		dispatch(editUserAction(finalData));
	};

	// For handle the table change click
	const tableChangeHandler = (_: any, __: any, data: any) => {
		performSortHandler!(data.order);
	};

	const [reinviteLoading, setReinviteLoading] = useState(false);

	// for check is there add permission
	const isAddUserPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Users',
		permission: ['add'],
	});
	// for check is there edit permission
	const isEditUserPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Users',
		permission: ['edit'],
	});
	// for check is there delete permission
	const isDeleteUserPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Users',
		permission: ['delete'],
	});

	const reinviteHandler = async (data: any) => {
		try {
			setReinviteLoading(true);
			const response = await postApi('/users/reinvite-user', {
				userId: data.userId,
				companyId: localStorage.getItem('companyId'),
				email: data.email,
				role: data.roleId,
			});

			setReinviteLoading(false);
			toastText(response.data.message, 'success');
		} catch (err: any) {
			if (err?.response?.data?.message) {
				toastText(err?.response?.data?.message, 'success');
			} else {
				toastText('Something went wrong in reinviting user.', 'success');
			}

			setReinviteLoading(false);
		}
	};

	// JSX
	return (
		<div className={'dynamic-table dynamic-table-new'}>
			<SearchAndFilter
				performSearchHandler={performSearchHandler}
				searchValue={searchValue}
				performFilterHandler={performFilterHandler}
				filterValue={filterValue}
			/>
			<Table
				dataSource={userDataSource}
				scroll={{ y: '55vh', x: '63vh' }}
				pagination={false}
				className="table-global"
				onChange={tableChangeHandler}
				rowKey={(record: any) => {
					return record.id;
				}}
			>
				<Column
					title="User Name"
					dataIndex="name"
					key="name"
					showSorterTooltip={{ title: '' }}
					defaultSortOrder="ascend"
					sorter={() => {
						return null as any;
					}}
					className="bg-white"
					sortDirections={['ascend', 'descend', 'ascend']}
					sortIcon={(data) => {
						return data.sortOrder === 'ascend' ? (
							<SortSvgTop />
						) : (
							<SortSvgBottom />
						);
					}}
				/>
				<Column
					title="Email Address"
					dataIndex="email"
					key="email"
					className="bg-white"
					// width={'379px'}
				/>
				<Column
					title="Phone Number"
					dataIndex="phone"
					key="phone"
					className="bg-white"
					// width={'314px'}
				/>
				<Column
					title="Role"
					dataIndex="role"
					key="role"
					className="bg-white"
					width={'15%'}
					// width={'261px'}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					className="bg-white"
					width={'10%'}
					// width={'176px'}
					render={(value: any, data: any) => {
						return (
							<Switch
								key={Math.random()}
								defaultChecked={value}
								disabled={
									data?.isCompanyAdmin ||
									!data.roleStatus ||
									!isEditUserPermission
								}
								onChange={(e) => statusHandler(e, data)}
							></Switch>
						);
					}}
				/>
				{(isEditUserPermission || isDeleteUserPermission) && (
					<Column
						title="Action"
						dataIndex="action"
						key="action"
						className="bg-white"
						width={'15%'}
						render={(value, data: any) => {
							return (
								<Space size={20}>
									{!(data.isCompanyAdmin || data.isAdmin) ? (
										<>
											{isEditUserPermission && (
												<div
													className="cursor-pointer flex align-center justify-center"
													onClick={() => editDataHandler(data)}
												>
													<EditActionSvg />
												</div>
											)}
											{isDeleteUserPermission && (
												<div
													className="cursor-pointer flex align-center justify-center"
													onClick={() => {
														deleteDataHandler(data);
														showModal();
													}}
												>
													<DeleteActionSvg />
												</div>
											)}
										</>
									) : null}
									<>
										{isAddUserPermission &&
											data.reinvite &&
											!data.isCompanyAdmin && (
												<Tooltip title="Reinvite user">
													<div
														className={`invite-style ${
															reinviteLoading && 'pointer-event-none'
														}`}
														onClick={() => reinviteHandler(data)}
													>
														<InviteSvg />
													</div>
												</Tooltip>
											)}
									</>
								</Space>
							);
						}}
					/>
				)}
			</Table>
		</div>
	);
};

export default DynamicTable;
