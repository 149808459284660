import { Modal, Row } from 'antd';
import { CloseSvg } from 'utils/svgs';
import { GraphInterface } from './types';
import { Bar, Line } from 'react-chartjs-2';
import { formatNumberWithCommasV2 } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';

const GraphModal = (props: GraphInterface) => {
	const { isModalOpen, handleCancel, chartData, chartType, maxNumber, title, displayTitle } =
		props;
	return (
		<Modal
			open={isModalOpen}
			onCancel={handleCancel}
			className="graph-modal"
			closable={false}
			width={'75%'}
			footer={false}
		>
			<Row className={styles['graph__header']}>
				<div className="userDetailsTitle">
					<b>{displayTitle}</b>
				</div>
				<div
					className={styles['graph__header-close']}
					onClick={handleCancel}
				>
					<CloseSvg />
				</div>
			</Row>
			<hr/>
			<div className={styles['graph']}>
				{chartType === 'barChart' && (
					<Bar
						options={{
							indexAxis: 'y' as const,
							elements: {
								bar: {
									borderWidth: 0,
									hoverBackgroundColor: '#9CB891',
								},
							},
							scales: {
								y: {
									ticks: {
										color: 'black',
										font: {
											size: 12,
										},
										maxTicksLimit: chartData.labels.length,
									},
									grid: {
										display: false,
									},
									beginAtZero: true,
								},
								x: {
									ticks: {
										callback: (tickValue) => {
											return title === 'employee-hours'
												? formatNumberWithCommasV2(tickValue)
												: `$${formatNumberWithCommasV2(tickValue)}`;
										},
										color: 'black',
										font: {
											size: 12,
										},
									},
								},
							},
							responsive: true,
							plugins: {
								legend: {
									display: false,
								},
								tooltip: {
									enabled: true,
									callbacks: {
										label: (tooltipItem: any) => {
											return title === 'employee-hours'
												? formatNumberWithCommasV2(tooltipItem.raw)
												: `$${formatNumberWithCommasV2(tooltipItem.raw)}`;
										},
									},
								},
							},
						}}
						data={chartData}
					/>
				)}
				{chartType === 'lineChart' && (
					<Line
						data={chartData}
						options={{
							responsive: true,
							scales: {
								y: {
									beginAtZero: true,
									max: Number(maxNumber),
									ticks: {
										callback: (tickValue) => {
											return `$${formatNumberWithCommasV2(tickValue)}`;
										},
										font: {
											size: 12,
										},
										color: 'black',
										maxTicksLimit: 5,
									},
								},
								x: {
									offset: true,
									grid: {
										display: false,
									},
									ticks: {
										color: 'black',
									},
								},
							},
							plugins: {
								legend: {
									display: false,
								},
								tooltip: {
									enabled: true,
									callbacks: {
										label: (tooltipItem: any) => {
											return `$${formatNumberWithCommasV2(tooltipItem.raw)}`;
										},
									},
								},
							},
						}}
					/>
				)}
			</div>
		</Modal>
	);
};

export default GraphModal;
