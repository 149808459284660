import { Select } from 'antd';
import styles from './index.module.scss';
import './index.scss';
import { DecimalSettingsProps } from './types';

const DecimalSettings = (props: DecimalSettingsProps) => {
	const { toFixedAmountHandler, toFixedPercentageHandler, decimalToFixedAmount, decimalToFixedPercentage } = props;
	return (
		<div className={styles['account-settings']}>
			<div className={styles['account-settings__wrapper']}>
				<div className={styles['account-settings__top']}>
					<p className={styles['account-settings__top--title']}>
						Decimal Settings
					</p>
				</div>
				<div className={styles['account-settings__center']}>
					<div className={styles['account-settings__center--expense']}>
						<label
							className={styles['account-settings__center--expense--label']}
						>
							Decimal Value For Cost Allocation - Percentage (%)
						</label>

						<Select
							value={decimalToFixedPercentage}
							style={{ width: 150 }}
							onChange={(value) => toFixedPercentageHandler(value)}
							options={[{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }]}
						/>
					</div>
					<div className={styles['account-settings__center--expense']}>
						<label
							className={styles['account-settings__center--expense--label']}
						>
							Decimal Value For Cost Allocation - Amount ($)
						</label>

						<Select
							value={decimalToFixedAmount}
							defaultValue={2}
							style={{ width: 150 }}
							onChange={(value) => toFixedAmountHandler(value)}
							options={[{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }]}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DecimalSettings;
