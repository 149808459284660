import { Table } from 'antd';
import Ellipse from 'components/Global/Ellipse';
import { EJournalStatus } from 'constants/Data';
import dayjs from 'dayjs';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import {
	getJournalListingAction,
	getJournalPaginateAction,
} from 'redux/action/journalAction';
import { AppDispatch } from 'redux/store';
import { useDebounce } from 'use-debounce';
import {
	PreviewSvg,
	QuickbooksEmployee,
	SortSvgBottom,
	SortSvgTop,
} from 'utils/svgs';
import { hasText } from 'utils/utils';
import PreviewJournal from '../PreviewJournal';
import SearchAndFilter from '../SearchAndFilter';
import styles from './index.module.scss';

const DynamicTable = () => {
	const { Column } = Table;

	const dispatch = useDispatch<AppDispatch>();

	// const [queryParams, setQueryParams] = useSearchParams();
	// const [payPeriodId, setPayPeriodId] = useState<string | null>(
	// 	queryParams.get('payPeriod') || null
	// );

	const [payPeriodId, setPayPeriodId] = useState<string | null>(null);

	const { journalListingData: data, journalListingLoading } = useSelector(
		(state: any) => state.journal
	);

	const payPeriods = useSelector((state: any) => state?.payPeriods?.optionData);

	const [isInViewPort, setIsInViewPort] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>('');
	const [debounceSearchValue] = useDebounce(searchValue, 1000);
	const [applySearch, setApplySearch] = useState<boolean>(false);
	const [sortBy, setSortBy] = useState<string | null>('');
	const [sortType, setSortType] = useState<string | null>('');
	const [filterValue, setFilterValue] = useState<any>({});
	const [pageNo, setPageNo] = useState(1);
	const [journalListingData, setJournalListingData] = useState([]);

	const [isPreviewJournalOpen, setIsPreviewJournalOpen] = useState(false);
	const [journalPayPeriodId, setJournalPayPeriodId] = useState<string | null>(
		null
	);
	const [journalPayPeriodData, setJournalPayPeriodData] = useState(null);
	const [year, setYear] = useState<any>(dayjs());

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const handleYear = (value: any) => {
		setApplySearch(true);
		setYear(value);
	};

	const qboMode = process.env.REACT_APP_QBO_MODE
		? process.env.REACT_APP_QBO_MODE
		: 'sandbox';

	const scrollHandler = useCallback((event: any) => {
		const { currentTarget } = event;
		const tableBody = currentTarget?.querySelector('tbody');
		if (
			tableBody?.getBoundingClientRect().top +
				tableBody.getBoundingClientRect().height <
			screen.height - 100
		) {
			setIsInViewPort(true);
		} else {
			setIsInViewPort(false);
		}
	}, []);

	const getJournalListingData = async (isPagination?: boolean) => {
		if (isPagination) {
			setPageNo((prev: any) => prev + 1);
		} else {
			setIsInViewPort(false);
			setPageNo(1);
		}

		const query: any = {
			page: isPagination ? pageNo + 1 : 1,
			limit: 10,
			search: searchValue,
			payPeriodId,
			sort: sortBy,
			type: sortType,
			year: year['$y'],
			...filterValue,
		};

		if (!hasText(query.employeeId)) {
			delete query.employeeId;
		}

		if (!hasText(query.customerId)) {
			delete query.customerId;
		}

		if (!hasText(query.classId)) {
			delete query.classId;
		}

		if (!hasText(searchValue)) {
			delete query.search;
		}

		if (isPagination) {
			await dispatch(getJournalPaginateAction(query));
			return;
		}
		await dispatch(getJournalListingAction(query));
	};

	const handleClosePreviewJournal = () => {
		setIsPreviewJournalOpen(false);
		setJournalPayPeriodId(null);
		setJournalPayPeriodData(null);
	};

	const journalValueHandler = (id: string) => {
		setJournalPayPeriodId(id);
		setIsPreviewJournalOpen(true);
	};

	const tableChangeHandler = (_: unknown, __: unknown, tableData: any) => {
		if (tableData.columnKey && tableData.order) {
			setApplySearch(true);
			setSortBy(tableData.columnKey);
			setSortType(tableData.order === 'ascend' ? 'asc' : 'desc');
		}
	};
	useEffect(() => {
		const tableBody = document.querySelector('.ant-table-body');
		tableBody?.addEventListener('scroll', scrollHandler);
		return () => {
			// Remove scroll event listener when component unmounts
			tableBody?.removeEventListener('scroll', scrollHandler);
		};
	}, [data]);

	useEffect(() => {
		getJournalListingData();
	}, []);

	useEffect(() => {
		setPayPeriodId('');
		setApplySearch(true);
		setSortBy('');
		setSortType('');
		setSearchValue('');
		setFilterValue({});
		setPageNo(1);
		setYear('');
	}, [selectedCompanyDetails]);

	useEffect(() => {
		if (journalPayPeriodId) {
			const selectedPayPeriod = payPeriods.find(
				(singlePayPeriod: any) => singlePayPeriod.id === journalPayPeriodId
			);
			setJournalPayPeriodData(selectedPayPeriod);
		}
	}, [payPeriods, journalPayPeriodId]);

	useEffect(() => {
		if (data && data.length > 0) {
			const finalData = data?.map((singleEntry: any) => {
				return {
					key: singleEntry?.id,
					id: singleEntry?.id,
					date: moment(singleEntry?.date).format('MM/DD/YYYY'),
					qboJournalNo: singleEntry?.qboJournalNo,
					amount: singleEntry?.amount,
					payPeriod: `${moment(singleEntry?.payPeriod?.startDate).format(
						'MM/DD/YYYY'
					)} -
						${moment(singleEntry?.payPeriod?.endDate).format('MM/DD/YYYY')}`,
					publishedBy: `${
						singleEntry?.createdBy?.firstName &&
						singleEntry?.createdBy?.firstName
					} ${
						singleEntry?.createdBy?.lastName && singleEntry?.createdBy?.lastName
					} on ${moment(singleEntry?.updatedAt).format('MM/DD/YYYY')}`,
					notes: singleEntry?.notes,
					status:
						singleEntry?.status === EJournalStatus.DRAFT
							? 'Draft'
							: singleEntry?.status === EJournalStatus.PUBLISHED ?'Published' :'Deleted',
					actions: singleEntry?.payPeriodId,
					qboLink: singleEntry?.qboJournalTrnId,
				};
			});
			setJournalListingData(finalData);
		} else {
			setJournalListingData([]);
		}
	}, [data]);

	useEffect(() => {
		(async () => {
			if (isInViewPort) {
				await getJournalListingData(true);
			}
		})();
	}, [isInViewPort]);

	useEffect(() => {
		if (payPeriodId) {
			// handlePayPeriodId(payPeriodId);
		}
	}, [payPeriodId]);

	useEffect(() => {
		if (applySearch) {
			getJournalListingData();
		}
	}, [
		payPeriodId,
		debounceSearchValue,
		filterValue,
		sortBy,
		sortType,
		year,
		selectedCompanyDetails,
	]);

	return (
		<>
			<div className={styles['journal-entries-container__header']}>
				<SearchAndFilter
					searchValue={searchValue}
					performSearchHandler={(value: any) => {
						setSearchValue(value);
						setApplySearch(true);
					}}
					onChangePayPeriod={(id: any) => {
						setPayPeriodId(id);
						setApplySearch(true);
						// setQueryParams({ payPeriod: id });
					}}
					selectedPayPeriod={payPeriodId}
					filterValue={filterValue}
					performFilterHandler={(key: any, value: any) => {
						setFilterValue({ ...filterValue, [key]: value });
						setApplySearch(true);
					}}
					clearFilter={() => {
						setPayPeriodId('');
						setApplySearch(true);
						setSortBy('');
						setSortType('');
						setSearchValue('');
						setFilterValue({});
						setPageNo(1);
						// setQueryParams({});
						setYear('');
					}}
					sortBy={sortBy}
					sortType={sortType}
					handleYear={handleYear}
					year={year}
				/>
			</div>
			<div className={styles['journal-entries-container__table']}>
				<Table
					dataSource={journalListingData}
					pagination={false}
					className={styles['dynamic-table']}
					onChange={tableChangeHandler}
					loading={journalListingLoading}
					scroll={{ y: '47vh' }}
					rowKey={(record: any) => {
						return record.id;
					}}
				>
					<Column
						title="Date"
						dataIndex="date"
						key="date"
						className="bg-white"
						// showSorterTooltip= { title: '' }
						defaultSortOrder="ascend"
						sorter={() => {
							return null as any;
						}}
						sortDirections={['ascend', 'descend', 'ascend']}
						sortIcon={(data: any) => {
							return data.sortOrder === 'ascend' ? (
								<SortSvgTop />
							) : (
								<SortSvgBottom />
							);
						}}
					/>
					<Column
						title="Pay Periods"
						dataIndex="payPeriod"
						key="payPeriod"
						className="bg-white"
						render={(value) => {
							return value;
						}}
						defaultSortOrder="ascend"
						align="left"
					/>
					<Column
						title="QuickBooks Journals No.#"
						dataIndex="qboLink"
						key="qboLink"
						className="bg-white"
						width="15%"
						render={(value, data: any) => {
							return data?.status === 'Published' ? (
								<a
									className={`${styles['dynamic-table__journalNo']} ${
										data?.status === EJournalStatus.DRAFT &&
										'pointer-event-none'
									}  `}
									href={`https://app${
										qboMode != 'prod' ? '.sandbox' : ''
									}.qbo.intuit.com/app/journal?txnId=${data?.qboLink}`}
									target="blank"
								>
									<span>#{data?.qboJournalNo}</span>
									<QuickbooksEmployee />
								</a>
							) : (
								<p
									className={`${
										styles['dynamic-table__journalNo']
									} ${'pointer-event-none'}  `}
								>
									{data.status === 'Deleted' ? (
										<span>#{data?.qboJournalNo}</span>
									) : (
										<span>{}</span>
									)}
								</p>
							);
						}}
						// defaultSortOrder="ascend"
						// sorter={() => {
						// 	return null as any;
						// }}
						// sortDirections={['ascend', 'descend', 'ascend']}
						// sortIcon={(data: any) => {
						// 	return data.sortOrder === 'ascend' ? (
						// 		<SortSvgTop />
						// 	) : (
						// 		<SortSvgBottom />
						// 	);
						// }}
					/>
					<Column
						title="Amount"
						dataIndex="amount"
						key="amount"
						className="bg-white"
						align="right"
						render={(value) => {
							return (
								<NumericFormat
									value={value}
									thousandSeparator=","
									decimalScale={2}
									fixedDecimalScale={true}
									displayType="text"
									prefix="$"
									renderText={(value) => (
										<p className={styles['dynamic-table__amount']}>
											{value ?? 0}
										</p>
									)}
								/>
							);
						}}
						defaultSortOrder="ascend"
						sorter={() => {
							return null as any;
						}}
						sortDirections={['ascend', 'descend', 'ascend']}
						sortIcon={(data: any) => {
							return data.sortOrder === 'ascend' ? (
								<SortSvgTop />
							) : (
								<SortSvgBottom />
							);
						}}
						width="10%"
					/>
					<Column
						title="Published By"
						dataIndex="publishedBy"
						key="publishedBy"
						className="bg-white"
					/>
					<Column
						title="Notes"
						dataIndex="notes"
						key="notes"
						className="bg-white"
						width="150px"
						render={(value: string) => (
							<Ellipse
								message={value}
								maxLength={15}
								isTooltip={true}
								tooltipMessage={value}
							/>
						)}
					/>
					<Column
						title="Status"
						dataIndex="status"
						key="status"
						className="bg-white dynamic-table-status"
						render={(value) => {
							return (
								<div className={styles['dynamic-table__journal-status']}>
									<span
										className={
											value == 'Published'
												? styles['dynamic-table__journal-status-published']
												: styles['dynamic-table__journal-status-draft']
										}
									>
										{value}
									</span>
								</div>
							);
						}}
						defaultSortOrder="ascend"
						sorter={() => {
							return null as any;
						}}
						sortDirections={['ascend', 'descend', 'ascend']}
						sortIcon={(data: any) => {
							return data.sortOrder === 'ascend' ? (
								<SortSvgTop />
							) : (
								<SortSvgBottom />
							);
						}}
					/>
					<Column
						title="Actions"
						dataIndex="actions"
						key="actions"
						className="bg-white"
						render={(value: string) => {
							return (
								<div className={styles['dynamic-table__journal-actions']}>
									{/* <span
										className={styles['dynamic-table__journal-actions-edit']}
										onClick={() => journalValueHandler(value)}
									>
										<EditJournalSvg />
									</span> */}
									<span
										className={styles['dynamic-table__journal-actions-preview']}
										onClick={() => journalValueHandler(value)}
									>
										<PreviewSvg />
									</span>
								</div>
							);
						}}
					/>
				</Table>
			</div>
			{isPreviewJournalOpen && (
				<PreviewJournal
					handleClosePreviewJournal={handleClosePreviewJournal}
					isPreviewJournalOpen={isPreviewJournalOpen}
					payPeriodId={journalPayPeriodId}
					payPeriodData={journalPayPeriodData}
					getJournalListingData={getJournalListingData}
				/>
			)}
		</>
	);
};

export default DynamicTable;
