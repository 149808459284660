import { Button, Col, Form, Modal, Row, Table, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { CloseSvg, EmailSvg, EmailWhite, ExportPdfWhite } from 'utils/svgs';
import styles from './index.module.scss';
import './index.scss';
import { EmployeeListModalProps } from './types';
import { getApiZip, postApi } from 'redux/apis';
import { toastText } from 'utils/utils';
import moment from 'moment';

const EmployeeListModal = (timeSheetData: EmployeeListModalProps) => {
	const { Column } = Table;
	const { selectedTimeSheet, timeSheetEmployeesData, closeEmployeeModal }: any =
		timeSheetData;
	const [form] = Form.useForm();

	const [employeeData, setEmployeeData] = useState([]);
	const [isEmailLoading, setIsEmailLoading] = useState(false);
	const [isExportPdfLoading, setIsExportPdfLoading] = useState(false);

	const [allChecked, setAllChecked] = useState(false);

	const handleCancel = () => {
		closeEmployeeModal();
	};

	const handleCheckbox = (value: any, all: boolean, data?: any) => {
		if (all) {
			const finalData: any = employeeData?.map((singleEmployee: any) => {
				return {
					...singleEmployee,
					selected: value,
				};
			});
			const falseData = finalData.filter(
				(singleEmp: any) => singleEmp.selected === false
			);

			if (falseData.length === 0) {
				setAllChecked(true);
			} else {
				setAllChecked(false);
			}

			setEmployeeData(finalData);
		} else {
			const finalData: any = employeeData?.map((singleEmployee: any) => {
				if (singleEmployee.employeeId === data?.employeeId) {
					return {
						...singleEmployee,
						selected: value,
					};
				} else {
					return singleEmployee;
				}
			});

			const falseData = finalData.filter(
				(singleEmp: any) => singleEmp.selected === false
			);

			if (falseData.length === 0) {
				setAllChecked(true);
			} else {
				setAllChecked(false);
			}

			setEmployeeData(finalData);
		}
	};

	const setSingleEmployeeLoading = (value: boolean, data: any) => {
		const finalData = timeSheetEmployeesData.map((singleEmp: any) => {
			if (singleEmp.employeeId === data?.employeeId) {
				return {
					...singleEmp,
					isSingleEmailLoading: value,
				};
			} else {
				return singleEmp;
			}
		});
		setEmployeeData(finalData);
	};

	const handleSingleMail = async (value: string, data: any) => {
		setSingleEmployeeLoading(true, data);
		try {
			await postApi(`/time-sheet/email`, {
				companyId: localStorage.getItem('companyId'),
				timeSheetId: selectedTimeSheet?.id,
				employeeList: [data?.employeeId],
			});
			setSingleEmployeeLoading(false, data);
			toastText('Time Sheet email sent successfully', 'success');
		} catch (err) {
			setSingleEmployeeLoading(false, data);
			toastText('Something went wrong in sending email', 'error');
		}
	};

	const handleSendEmail = async () => {
		try {
			const employees: any = [];

			employeeData?.forEach((singleEmployee: any) => {
				if (singleEmployee.selected == true) {
					employees.push(singleEmployee.employeeId);
				}
			});

			// Send email api call

			setIsEmailLoading(true);

			if (employees.length > 0) {
				await postApi(`/time-sheet/email`, {
					companyId: localStorage.getItem('companyId'),
					timeSheetId: selectedTimeSheet?.id,
					employeeList: employees,
				});

				setIsEmailLoading(false);
				toastText('Time Sheet email sent successfully', 'success');
				closeEmployeeModal();
			} else {
				setIsEmailLoading(false);
				toastText('No employees are selected', 'error');
			}
		} catch (err) {
			setIsEmailLoading(false);
			toastText('Something went wrong in sending time sheet email', 'error');
		}
	};

	const handlePdfExport = async () => {
		try {
			const employees: any = [];
			employeeData?.forEach((singleEmployee: any) => {
				if (singleEmployee.selected == true) {
					employees.push({
						id: singleEmployee.employeeId,
						employeeName: singleEmployee.employeeName,
					});
				}
			});

			if (employees.length > 0) {
				setIsExportPdfLoading(true);

				const zipFileNames: any = [];

				await Promise.all(
					await employees?.map(async (singleEmployee: any) => {
						const response = await postApi(`/time-sheet/export`, {
							companyId: localStorage.getItem('companyId'),
							timeSheetId: selectedTimeSheet?.id,
							employeeId: singleEmployee?.id,
							fileName: `${singleEmployee?.employeeName}_${moment(
								new Date()
							).format('MMDDYYYYhhmmss')}`,
						});
						zipFileNames.push(response.data.data);
					})
				);

				const response = await getApiZip(`/time-sheet/export-zip`, {
					fileNames: zipFileNames,
				});

				const blob = new Blob([response.data], { type: 'application/zip' });

				const url = window.URL.createObjectURL(blob);

				// Create a link element
				const link = document.createElement('a');

				// Set the link's href attribute to the temporary URL
				link.href = url;

				// Set the download attribute to specify the filename
				link.download = `TimeSheet_${moment(new Date()).format(
					'MMDDYYYYhhmmss'
				)}.zip`;

				// Append the link to the document body
				document.body.appendChild(link);

				// Trigger a click on the link to start the download
				link.click();

				// Remove the link from the document body
				document.body.removeChild(link);

				// Revoke the temporary URL to free up resources
				window.URL.revokeObjectURL(url);

				// await Promise.all(
				// 	await employees?.map(async (singleEmployee: any) => {
				// 		const response = await postApi(`/time-sheet/export`, {
				// 			companyId: localStorage.getItem('companyId'),
				// 			timeSheetId: selectedTimeSheet?.id,
				// 			employeeId: singleEmployee?.id,
				// 		});

				// 		const pdf = {
				// 			file: response?.data?.data,
				// 			fileName: `${singleEmployee?.employeeName}_${moment(
				// 				new Date()
				// 			).format('MMDDYYYYhhmmss')} `,
				// 		};
				// 		// const pdfLink = `${pdf.file}`;
				// 		const anchorElement = document.createElement('a');
				// 		const fileName = `${pdf.fileName}.pdf`;
				// 		anchorElement.href = 'data:application/pdf;base64,' + pdf?.file;
				// 		anchorElement.download = fileName;
				// 		anchorElement.click();
				// 	})
				// );

				setIsExportPdfLoading(false);
				closeEmployeeModal();
			} else {
				setIsExportPdfLoading(false);
				toastText('No employees are selected', 'error');
			}
		} catch (err) {
			toastText('Something went wrong in exporting email', 'error');
		}
	};

	useEffect(() => {
		const data = timeSheetEmployeesData.map((singleEmp: any) => {
			return {
				...singleEmp,
				key: singleEmp.employeeId,
				selected: false,
				isSingleEmailLoading: false,
			};
		});
		setEmployeeData(data);
	}, [timeSheetEmployeesData]);

	return (
		<Modal
			open={true}
			onCancel={handleCancel}
			okText={'Save'}
			closable={false}
			footer={null}
			className="time-sheet-modal profile__popup"
		>
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				autoComplete="off"
				onFinish={handleSendEmail}
				form={form}
			>
				<Row className={styles['time-sheet-modal__header']}>
					<div className="userDetailsTitle">
						<b>{selectedTimeSheet.name}</b>
					</div>
					<div
						className={styles['time-sheet-modal__header-delete']}
						onClick={handleCancel}
					>
						<CloseSvg />
					</div>
				</Row>
				<hr />
				<div className="time-sheet-modal__body">
					<Table
						dataSource={employeeData}
						scroll={{ y: '50vh' }}
						pagination={false}
						className="employee-list-modal"
					>
						<Column
							title={
								<Checkbox
									onChange={(e) => {
										handleCheckbox(e.target.checked, true);
									}}
									checked={allChecked}
									defaultChecked={allChecked}
								/>
							}
							key="selected"
							dataIndex="selected"
							width={'50px'}
							className="bg-white"
							render={(value: boolean, data: any) => {
								return (
									<Checkbox
										onChange={(e) =>
											handleCheckbox(e.target.checked, false, data)
										}
										checked={value}
										defaultChecked={value}
									/>
								);
							}}
						/>
						<Column
							title="Employee"
							dataIndex="employeeName"
							key="employeeName"
							className="bg-white"
						/>
						<Column
							title="Hrs"
							dataIndex="approvedHours"
							key="approvedHours"
							width={'80px'}
							className="bg-white"
						/>
						<Column
							title="Email"
							dataIndex="email"
							key="email"
							className="bg-white"
							render={(value: string, data: any) => (
								<Button
									className={`${styles['time-sheet-modal__body__button']} ${
										data.isSingleEmailLoading && 'pointer-event-none'
									}`}
									block={true}
									onClick={() => handleSingleMail(value, data)}
								>
									{data.isSingleEmailLoading ? (
										<img src="/assets/gifs/loading-black.gif" height={40} />
									) : (
										<div
											className={
												styles['time-sheet-modal__body__send-email-inside']
											}
										>
											<EmailSvg />
											<p>Send </p>
										</div>
									)}
								</Button>
							)}
						/>
					</Table>
				</div>
				<div className="time_sheet_modal_footer">
					<Row
						justify="start"
						className={styles['time-sheet-modal__body__buttons']}
						gutter={16}
					>
						<Col xs={12} md={7} lg={7} sm={8}>
							<Button
								className={`${styles['time-sheet-modal__body__send-email']} ${
									isEmailLoading && 'pointer-event-none'
								}`}
								block={true}
								onClick={handleSendEmail}
							>
								{isEmailLoading ? (
									<img src="/assets/gifs/loading-black.gif" height={40} />
								) : (
									<div
										className={
											styles['time-sheet-modal__body__send-email-inside']
										}
									>
										<EmailWhite />
										<p>Send Email</p>
									</div>
								)}
							</Button>
						</Col>
						<Col xs={12} md={7} lg={7} sm={8}>
							<Button
								className={`${styles['time-sheet-modal__body__send-email']} ${
									isExportPdfLoading && 'pointer-event-none'
								}`}
								block={true}
								onClick={handlePdfExport}
							>
								{isExportPdfLoading ? (
									<img src="/assets/gifs/loading-black.gif" height={40} />
								) : (
									<div
										className={
											styles['time-sheet-modal__body__send-email-inside']
										}
									>
										<ExportPdfWhite />
										<p>Export as PDF</p>
									</div>
								)}
							</Button>
						</Col>
					</Row>
				</div>
			</Form>
		</Modal>
	);
};

export default EmployeeListModal;
